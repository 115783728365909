import ClientRegister from '../pages/Register';
import Success from '../pages/Success';
import Login from '../pages/Login';
import Client from '../pages/Client';
import Conditions from '../pages/Conditions';
import MyDeals from 'pages/MyDeals';
import { useEffect } from 'react';
import { Switch, Router, useLocation } from 'react-router-dom';
import history from '../utils/history';
import CustomRoute from './CustomRoute';
import Documents from 'pages/Documents';
import ClientRegisterUpdate from 'pages/RegisterUpdate';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function Routes() {
  return (
    <Router history={history}>
      <ScrollToTop />
      <Switch>
        <CustomRoute path="/" exact component={Login} />
        <CustomRoute path="/client" isPrivate exact component={Client} />
        <CustomRoute
          path="/conditions"
          isPrivate
          exact
          component={Conditions}
        />
        <CustomRoute
          path="/register"
          isPrivate
          exact
          component={ClientRegister}
        />
        <CustomRoute
          path="/register-update"
          isPrivate
          exact
          component={ClientRegisterUpdate}
        />
        <CustomRoute path="/sucess" isPrivate exact component={Success} />
        <CustomRoute path="/mydeals" isPrivate exact component={MyDeals} />
        <CustomRoute path="/documentos" isPrivate exact component={Documents} />
        <CustomRoute path="/*" isPrivate exact component={Login} />
      </Switch>
    </Router>
  );
}
