import Input from 'components/Input';
import MaskedInput from 'components/MaskedInput';
import Select from 'components/Select';
import { FormikTouched } from 'formik';
import {
  EstadoCivilProps,
  ProfissaoProps,
  TipoOcupacaoProps,
} from 'pages/Register/utils/personProps';
import {
  FormPropsType,
  FormPropsWithNoAvalistaType,
} from 'pages/Register/utils/types/FormPropType/FormPropType';
import React from 'react';
import { currencyFormatWithCents, unMask } from 'utils/mask';
import * as S from '../InputsAndSelectsOfBasicInformation/styles';
import { find } from 'lodash';

type InputsAndSelectsOfProfissionalInformationProps = {
  values: FormPropsType;
  errorsForm: FormPropsType | FormPropsWithNoAvalistaType | undefined;
  successForm: FormPropsType | FormPropsWithNoAvalistaType | undefined;
  touched: FormikTouched<FormPropsType>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  validOnChangeAfterError: (
    campSelect: string,
    eventTargetValue: string,
    values: FormPropsType,
    listaDeProfissao: ProfissaoProps[]
  ) => void;
  listaProfissoes: ProfissaoProps[];
  formSubmited: boolean;
  ocupationCurrentTagValidation: string;
  ocupationList: TipoOcupacaoProps[];
  setErrorsForm: React.Dispatch<React.SetStateAction<FormPropsType>>;
  setTipoOcupacao: (value: any) => void;
  setFormSubmited: (value: React.SetStateAction<boolean>) => void;
  listaComprovantes: ProfissaoProps[];
  messageErrorValidationDateAdmission: string;
  maritalStatusList: EstadoCivilProps[];
};

export const InputsAndSelectsOfProfissionalInformation: React.FC<
  InputsAndSelectsOfProfissionalInformationProps
> = ({
  values,
  errorsForm,
  successForm,
  touched,
  setFieldValue,
  validOnChangeAfterError,
  listaProfissoes,
  ocupationCurrentTagValidation,
  ocupationList,
  setErrorsForm,
  setTipoOcupacao,
  setFormSubmited,
  listaComprovantes,
  messageErrorValidationDateAdmission,
}) => {
  const changeTypeOcupation = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue('atividade', event.target.value);
    setTipoOcupacao(event.target.value);
    const arrayOfObject = Object.entries(errorsForm || {});
    const newArray = arrayOfObject.map((posicao) => [
      posicao[0],
      (posicao[1] = ''),
    ]);
    const newObject = Object.fromEntries(newArray);
    setErrorsForm(newObject);
    setFormSubmited(false);
  };

  return (
    <S.Wrapper>
      <S.DivInput>
        <Select
          title="Profissão"
          name="profissao"
          value={values.profissao}
          error={
            errorsForm?.profissao === 'true' &&
            successForm?.profissao === 'false'
          }
          valid={
            !!values.profissao &&
            !!touched.profissao &&
            errorsForm?.profissao === 'false'
          }
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('profissao', event.target.value);
          }}
        >
          <option value="" hidden>
            Selecione uma opção
          </option>
          {listaProfissoes.map((val) => (
            <option key={val.id} value={val.descricao}>
              {val.descricao}
            </option>
          ))}
        </Select>

        <Select
          title="Tipo Ocupação"
          name="atividade"
          value={values.atividade}
          error={
            errorsForm?.atividade === 'true' &&
            successForm?.atividade === 'false'
          }
          valid={
            !!touched.atividade &&
            !!values.atividade &&
            errorsForm?.atividade === 'false'
          }
          onChange={changeTypeOcupation}
        >
          <option value="" hidden>
            Selecione uma opção
          </option>

          {ocupationList.map((val) => (
            <option key={val.codigo} value={val.codigo}>
              {val.descricao}
            </option>
          ))}
        </Select>

        <Select
          title="Comprovação"
          name="comprovacao"
          value={values.comprovacao}
          error={
            errorsForm?.comprovacao === 'true' &&
            successForm?.comprovacao === 'false'
          }
          valid={
            !!values.comprovacao &&
            !!touched.comprovacao &&
            errorsForm?.comprovacao === 'false'
          }
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('comprovacao', event.target.value);
          }}
        >
          <option value="" hidden>
            Selecione uma opção
          </option>
          {listaComprovantes.map((val) => (
            <option key={val.id} value={val.descricao}>
              {val.descricao}
            </option>
          ))}
        </Select>
        <MaskedInput
          title="Renda Mensal"
          name="renda"
          value={
            ocupationCurrentTagValidation.length > 0 &&
            !ocupationCurrentTagValidation.match('#renda#')
              ? (values.renda = '')
              : values.renda
          }
          disabled={!ocupationCurrentTagValidation.match('#renda#')}
          MaskType={'currency'}
          maxInputLength={15}
          inputMethod="numeric"
          error={errorsForm?.renda === 'true' && successForm?.renda === 'false'}
          valid={
            !!touched.renda && !!values.renda && errorsForm?.renda === 'false'
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              'renda',
              unMask(
                currencyFormatWithCents(unMask(event.target.value, '[^0-9^,]')),
                '[^0-9^,]'
              )
            );
            validOnChangeAfterError(
              'renda',
              unMask(
                currencyFormatWithCents(unMask(event.target.value, '[^0-9^,]')),
                '[^0-9^,]'
              ),
              values,
              listaProfissoes
            );
          }}
        />

        <Input
          title="Empresa"
          name="empresa"
          value={
            ocupationCurrentTagValidation.length > 0 &&
            !ocupationCurrentTagValidation.match('#rp_nome_empresa#')
              ? (values.empresa = '')
              : values.empresa
          }
          maxInputLength={50}
          error={
            errorsForm?.empresa === 'true' && successForm?.empresa === 'false'
          }
          valid={
            !!values.empresa &&
            !!touched.empresa &&
            errorsForm?.empresa === 'false'
          }
          disabled={!ocupationCurrentTagValidation.match('#rp_nome_empresa#')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('empresa', event.target.value);
            validOnChangeAfterError(
              'empresa',
              event.target.value,
              values,
              listaProfissoes
            );
          }}
        />

        <MaskedInput
          title={
            values.telEmpresa.length === 11
              ? 'Telefone empresa (Celular)'
              : 'Telefone empresa'
          }
          name="telEmpresa"
          value={
            ocupationCurrentTagValidation.length > 0 &&
            !ocupationCurrentTagValidation.match('#rp_telefone#')
              ? (values.telEmpresa = '')
              : values.telEmpresa
          }
          MaskType={'telefone'}
          maxInputLength={15}
          disabled={!ocupationCurrentTagValidation.match('#rp_telefone#')}
          error={
            errorsForm?.telEmpresa === 'true' &&
            successForm?.telEmpresa === 'false'
          }
          valid={
            !!touched.telEmpresa &&
            !!values.telEmpresa &&
            errorsForm?.telEmpresa === 'false'
          }
          inputMethod="numeric"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('telEmpresa', unMask(event.target.value, '[^0-9]'));
            validOnChangeAfterError(
              'telEmpresa',
              unMask(event.target.value, '[^0-9]'),
              values,
              listaProfissoes
            );
          }}
        />

        <Input
          title="Cargo"
          name="cargo"
          value={
            ocupationCurrentTagValidation.length > 0 &&
            !ocupationCurrentTagValidation.match('profissao')
              ? (values.cargo = '')
              : values.cargo
          }
          maxInputLength={50}
          disabled={!ocupationCurrentTagValidation.match('#profissao#')}
          error={errorsForm?.cargo === 'true' && successForm?.cargo === 'false'}
          valid={
            !!values.cargo && !!touched.cargo && errorsForm?.cargo === 'false'
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('cargo', event.target.value);
            validOnChangeAfterError(
              'cargo',
              event.target.value,
              values,
              listaProfissoes
            );
          }}
        />

        <MaskedInput
          title="Admissão (mês/ano)"
          name="mesAnoAdmissao"
          value={
            ocupationCurrentTagValidation.length > 0 &&
            !ocupationCurrentTagValidation.match('#rp_admissao#')
              ? (values.mesAnoAdmissao = '')
              : values.mesAnoAdmissao
          }
          MaskType={'dateMA'}
          maxInputLength={7}
          disabled={!ocupationCurrentTagValidation.match('#rp_admissao#')}
          error={
            errorsForm?.mesAnoAdmissao === 'true' &&
            successForm?.mesAnoAdmissao === 'false'
          }
          errorMessage={
            messageErrorValidationDateAdmission !== ''
              ? messageErrorValidationDateAdmission
              : 'Data Inválida'
          }
          valid={
            !!touched.mesAnoAdmissao &&
            !!values.mesAnoAdmissao &&
            errorsForm?.mesAnoAdmissao === 'false'
          }
          inputMethod="numeric"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              'mesAnoAdmissao',
              unMask(event.target.value, '[^0-9]')
            );
            validOnChangeAfterError(
              'mesAnoAdmissao',
              unMask(event.target.value, '[^0-9]'),
              values,
              listaProfissoes
            );
          }}
        />
        <S.CEPEmpWrapper>
          <MaskedInput
            name="cepEmpresa"
            title="CEP empresa"
            value={
              ocupationCurrentTagValidation.length > 0 &&
              !ocupationCurrentTagValidation.match('rp_municipio')
                ? (values.cepEmpresa = '')
                : values.cepEmpresa
            }
            MaskType={'cep'}
            maxInputLength={9}
            disabled={!ocupationCurrentTagValidation.match('rp_municipio')}
            error={
              errorsForm?.cepEmpresa === 'true' &&
              successForm?.cepEmpresa === 'false'
            }
            valid={
              !!touched.cepEmpresa &&
              !!values.cepEmpresa &&
              errorsForm?.cepEmpresa === 'false'
            }
            errorMessage={
              errorsForm?.cepEmpresa === 'true' &&
              successForm?.cepEmpresa === 'false'
                ? 'Cep Inválido'
                : ''
            }
            inputMethod="numeric"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('cepEmpresa', unMask(event.target.value, '[^0-9]'));
              validOnChangeAfterError(
                'cepEmpresa',
                unMask(event.target.value, '[^0-9]'),
                values,
                listaProfissoes
              );
            }}
          />

          <Input
            title="UF"
            name="ufEmpresa"
            value={
              ocupationCurrentTagValidation.length > 0 &&
              !ocupationCurrentTagValidation.match('rp_municipio')
                ? (values.ufEmpresa = '')
                : values.ufEmpresa
            }
            maxInputLength={2}
            disabled={!ocupationCurrentTagValidation.match('rp_municipio')}
            error={
              errorsForm?.ufEmpresa === 'true' &&
              successForm?.ufEmpresa === 'false'
            }
            valid={
              !!touched.ufEmpresa &&
              !!values.ufEmpresa &&
              errorsForm?.ufEmpresa === 'false'
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                'ufEmpresa',
                event.target.value.replace(/[^A-Za-z]/g, '').toUpperCase()
              );
              validOnChangeAfterError(
                'ufEmpresa',
                event.target.value.replace(/[^A-Za-z]/g, '').toUpperCase(),
                values,
                listaProfissoes
              );
            }}
          />
        </S.CEPEmpWrapper>
        <Input
          title="Cidade"
          name="cidadeEmpresa"
          value={
            ocupationCurrentTagValidation.length > 0 &&
            !ocupationCurrentTagValidation.match('rp_municipio')
              ? (values.cidadeEmpresa = '')
              : values.cidadeEmpresa
          }
          maxInputLength={100}
          disabled={!ocupationCurrentTagValidation.match('rp_municipio')}
          error={
            errorsForm?.cidadeEmpresa === 'true' &&
            successForm?.cidadeEmpresa === 'false'
          }
          valid={
            !!touched.cidadeEmpresa &&
            !!values.cidadeEmpresa &&
            errorsForm?.cidadeEmpresa === 'false'
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('cidadeEmpresa', event.target.value.toUpperCase());
            validOnChangeAfterError(
              'cidadeEmpresa',
              event.target.value.toUpperCase(),
              values,
              listaProfissoes
            );
          }}
        />

        <Input
          title="Observações"
          name="observacoes"
          value={values.observacoes}
          maxInputLength={100}
          valid={!!touched.observacoes && !!values.observacoes}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('observacoes', event.target.value);
          }}
        />
      </S.DivInput>
    </S.Wrapper>
  );
};
