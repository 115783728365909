import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding-top: 37px;
  padding-bottom: 37px;

  ${media.lessThan('large')`
      padding: 2rem 1rem;
    `}

  @media only screen and (max-width: 1000px) {
    padding: 3rem 0rem;
    height: auto;
  }
  ${media.lessThan('small')`
      height: auto;
    `}
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 436px 436px;
  grid-gap: 87.5px;
  max-height: 800px;
  max-width: 961.5px;
  height: 100%;
  width: 100%;
  margin: 0 auto;

  ${media.lessThan('large')`
      grid-template-columns: 1fr 1fr;
      grid-gap: 4rem;
    `}
  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    align-items: center;
  }
  ${media.lessThan('small')`
      height: auto;
      grid-template-columns: 1fr;
    `}
`;
export const Left = styled.div`
  width: 100%;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  @media only screen and (max-width: 1000px) {
    padding-bottom: 4rem;
  }
`;

export const HContainer = styled.div`
  width: 100%;
  max-width: 961.5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  hr {
    width: 100%;
    margin-top: 24px;
  }
`;

export const HContainerAround = styled.div`
  width: 100%;
  max-width: 961.5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
`;

export const VContainer = styled.div`
  width: 100%;
  max-width: 961.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const BasicInfo = styled.div`
  width: 100%;
  margin-top: 37px;
  @media only screen and (max-width: 1000px) {
    height: auto;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: center;
  }
  ${media.lessThan('small')`
      display: flex;
      flex-direction: column;
      width: 98vw;
      padding: 0 1rem;
    `}
`;

export const ProfessionalInfo = styled(BasicInfo)`
  ${media.lessThan('small')`
    margin-top: 1rem;
    margin-bottom: 2rem;
  `}
`;
export const AddressContact = styled(BasicInfo)`
  display: grid;
  grid-template-columns: 1fr;

  ${media.lessThan('small')`
      height: auto;
      width: 100vw;
      grid-gap: 2rem;
    `}
`;
