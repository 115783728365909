import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import * as S from './styles';
import * as CXT from 'context/globalContext';
import 'firebase/analytics';
import firebase from 'firebase/compat/app';

import { Header } from 'components/Header';
import { findData } from 'services/findCEP';
import { Pedido, pedidoForm, pedidoIdForm } from 'services/pedido';
import { PopUpDanger } from '../Register/components/PopUpDanger';
import { PopUpWarning } from '../Register/PopUpWarning';
import { TitleWrapperRegister } from './components/TitleWrapperRegister';
import { returnOpcaoSimulacaoFinanciamento } from './utils/returnObject/returnOpcaoSimulacaoFinanciamento';
import PessoaFisicaForm from 'pages/RegisterUpdate/pessoa-fisica';
import { padStart, size } from 'lodash';
import { unMask } from 'utils/mask';
import { Person } from 'services/client';
import { ShowPopUp } from './components/ShowPopup';

Bugsnag.start({
  apiKey: '00621c4c68a1b2bd34e7458c0f8d29bc',
  plugins: [new BugsnagPluginReact()],
});

export default function ClientRegisterUpdate() {
  const history = useHistory();
  const analytics = firebase.analytics();
  const globalContext = useContext(CXT.Context);

  const [identifier, setIdentifier] = useState<string>('');
  const [identifierType, setIdentifierType] = useState<
    'CPF' | 'CNPJ' | undefined
  >(undefined);

  const [municipios, setMunicipios] = useState<any[]>([]);
  const [popUpDangerActive, setPopUpDangerActive] = useState<boolean>(false);

  const [person, setPerson] = useState(
    JSON.parse(localStorage.getItem('client') || '{}')
  );
  const [car, setCar] = useState<any>(undefined);

  const carData = JSON.parse(localStorage.getItem('car') || '{}');
  const parcel = JSON.parse(localStorage.getItem('parcel') || '{}');
  const vehiclePlate = JSON.parse(localStorage.getItem('vehiclePlate') || '{}');
  useEffect(() => {
    getOptions();

    if (carData.marcas === undefined || parcel.prazo === undefined) {
      history.push('/client');
    } else {
      if (carData.marcas?.length > 0) {
        setCar(carData.marcas[0].linhas[0].versoes[0].modelos[0].veiculos[0]);
      }
    }

    if (person?.cpf) {
      triggerLoadData(`${person.cpf}`);
    }
  }, []);

  const triggerLoadData = (cpfCnpj?: string) => {
    if (!cpfCnpj) {
      cpfCnpj = identifier;
    }

    if (cpfCnpj) {
      let length = size(unMask(cpfCnpj, '\\.|-'));
      if (length < 11) {
        cpfCnpj = padStart(cpfCnpj, 11, '0');
        length = size(cpfCnpj);
      }
      const type = length === 14 ? 'CNPJ' : length === 11 ? 'CPF' : undefined;
      setIdentifier(cpfCnpj);
      setIdentifierType(type);
    }
  };

  const onMissingCustomerInformation = () => {
    history.push('/client');
  };

  const handleCheckId = (response: any) => {
    const dataResponse = response.data.data;

    if (dataResponse.status === 'OK') {
      globalContext.setIsLoading(false);
      history.push('/sucess');
    }

    if (dataResponse.status === 'NOK') {
      globalContext.setCodPropostaExistente(dataResponse.codPropostaExistente);

      switch (dataResponse.tipoValidacao) {
        case 'PROPOSTA_PARA_ATUALIZAR':
          globalContext.setPopUpActive([true, false, false, false]);
          globalContext.setIsLoading(false);
          break;
        case 'PROPOSTA_EM_ANALISE':
          globalContext.setPopUpActive([false, true, false, false]);
          globalContext.setIsLoading(false);
          break;
        case 'PROPOSTA_EM_ANDAMENTO':
          globalContext.setPopUpActive([false, false, true, false]);
          globalContext.setIsLoading(false);
          break;
        case 'PROPOSTA_RECUSADA':
          globalContext.setPopUpActive([false, false, false, true]);
          globalContext.setIsLoading(false);
          break;
        case 'LIMITE_PROPOSTAS_EM_ANDAMENTO':
          globalContext.setWarningData({
            title: 'AÇÃO INDISPONÍVEL',
            label: dataResponse.mensagemRetornoHtml ?? '',
            isVehicleName: false,
            isActive: true,
            outOfOrderVehicle: false,
            returnMessageVehicle: '',
          });
          globalContext.setIsLoading(false);
          break;
        default:
          globalContext.setIsLoading(false);
          toast.error(dataResponse.mensagem);
      }
    }
  };

  const postPedido = (response: any) => {
    if (!response) {
      globalContext.setIsLoading(false);
      return;
    }

    let opcoes = null;
    if (parcel && parcel.opcoes?.length > 0) {
      opcoes = parcel.opcoes[0];
    }

    if (car !== null && opcoes !== null) {
      const pedido: pedidoForm = {
        cancelarPedidoOriginal: false,
        pessoaClienteId: person.id,
        criarReserva: false,

        opcaoSimulacaoFinanciamentoRequest:
          returnOpcaoSimulacaoFinanciamento(opcoes),
        observacao: '',
        origemPedido: 'PORTAL_LOJISTA',
        valorDespachante: car.valorDespachante,
        valorEntradaAVista: opcoes.valorEntradaAVista,
        valorSpm: 0,
        valorTotalVeiculo: car.preco,
        veiculoEstoqueId: car.veiculoId,
      };
      Pedido.PostPedido(pedido).then((event: any) => {
        const orderId = event.data.data.id;

        localStorage.setItem('PedidoId', orderId);
        // analytics.setUserProperties(globalContext.properties);
        analytics.logEvent('CK_PHASE_THREE', {
          idPedido: orderId,
          cliente: person.cpf,
          placa: car.plate,
        });
        globalContext.setIdPedido(orderId);

        const form: pedidoIdForm = {
          pedidoId: orderId,
          versaoValidacao: '3',
          codPropostaAtualizar: null,
        };
        globalContext.setPopUpNumPedido(orderId);

        Pedido.PostIDPedidoV2(form)
          .then((response) => {
            handleCheckId(response);
          })
          .catch((err) => {
            Bugsnag.notify(err);
          })
          .finally(() => globalContext.setIsLoading(false));
      });
    }
  };

  const validateEstoqueAndCustomer = () => {
    return new Promise((resolve, error) => {
      const bodyBudget = {
        escopoValidacao: 'ORCAMENTO_INICIAR',
        veiculoEstoqueId: vehiclePlate?.id,
        pessoaClienteId: person.id,
      };

      Person.BudgetRequest(bodyBudget).then((response) => {
        const dataBudget = response.data.data;

        if (dataBudget.statusRetorno === 'CLIENTE_IMPEDIDO') {
          globalContext.setIsLoading(false);

          error();
          return setPopUpDangerActive(true);
        }

        if (dataBudget.statusRetorno === 'CLIENTE_DESENQUADRADO') {
          globalContext.setIsLoading(false);

          error();
          return setPopUpDangerActive(true);
        }

        if (dataBudget.statusRetorno === 'VEICULO_DESENQUADRADO') {
          globalContext.setIsLoading(false);

          error();
          return globalContext.setWarningData({
            title: 'AÇÃO INDISPONÍVEL',
            label:
              'Infelizmente não será possível iniciar o orçamento para o veículo:',
            isVehicleName: true,
            isActive: true,
            outOfOrderVehicle: false,
            returnMessageVehicle: dataBudget.veiculoConsultaSituacao ?? '',
          });
        }

        if (dataBudget.statusRetorno === 'VEICULO_DESENQUADRADO_SITUACAO') {
          globalContext.setIsLoading(false);

          error();
          return globalContext.setWarningData({
            title: 'AÇÃO INDISPONÍVEL',
            label:
              'Infelizmente não será possível solicitar F&I para o veículo:',
            isVehicleName: false,
            isActive: true,
            outOfOrderVehicle: true,
            returnMessageVehicle: dataBudget.veiculoConsultaSituacao ?? '',
          });
        }

        resolve(undefined);
      });
    });
  };

  const handlePedido = (personSubmitResponse: any) => {
    validateEstoqueAndCustomer()
      .then(() => {
        postPedido(personSubmitResponse);
      })
      .catch(() => {
        console.log('Não foi possível validar o estoque');
      });
  };

  async function getOptions() {
    findData
      .GetMunicipio()
      .then((response) => {
        setMunicipios(response.data.data);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        toast.error('Erro ao carregar os municípios');
      });
  }

  return (
    <>
      <Header onRefreshClick={getOptions} />
      <S.Wrapper>
        <TitleWrapperRegister />
        {identifierType === 'CPF' && (
          <PessoaFisicaForm
            customerCpf={identifier}
            municipios={municipios}
            stopLoadingOnFinish={false}
            finishCallback={handlePedido}
            missingCustomerDataCallback={onMissingCustomerInformation}
          />
        )}
        {popUpDangerActive && (
          <PopUpDanger setPopUpDangerActive={setPopUpDangerActive} />
        )}
      </S.Wrapper>
      {globalContext.warningData.isActive && (
        <PopUpWarning
          title={globalContext.warningData.title}
          label={globalContext.warningData.label}
          isVehicleName={globalContext.warningData.isVehicleName}
          setWarningData={globalContext.setWarningData}
          outOfOrderVehicle={globalContext.warningData.outOfOrderVehicle}
          returnMessageVehicle={globalContext.warningData.returnMessageVehicle}
        />
      )}
      <ShowPopUp handleCheckId={handleCheckId} />
    </>
  );
}
