import axios from 'axios';
import { Vencimento } from 'pages/Documents/Contract/Stage/01.Vencimento/EscolherVencimento';
import apiCar from '../api/api-car';

const RESOURCE_DEALS = '/propostas';

export const Deals = {
  GetAllDeals: async () =>
    await apiCar.get(`${RESOURCE_DEALS}/filtrar?search=`, {
      data: {},
    }),
  GetFilteredDeals: async (filter: string) => {
    return await apiCar.get(`${RESOURCE_DEALS}/filtrar?search=${filter}`, {
      data: {},
    });
  },
  GetDeal: async (numPedido: string) => {
    return await apiCar.get(`${RESOURCE_DEALS}/V2/${numPedido}`, {
      data: {
        acaoVisualizarContrato: false,
      },
    });
  },
  GetDoc: async (numPedido: string, numDoc: number) => {
    return await apiCar.get(
      `${RESOURCE_DEALS}/${numPedido}/documentos/${numDoc}/buscar`,
      {
        data: {},
      }
    );
  },
  DownloadDoc: async (apiUrl: string) => {
    return await axios.get(apiUrl, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/pdf',
      },
    });
  },

  UploadDoc: async (
    numPedido: string,
    id: number,
    observacao: string,
    arquivos: Array<{ arquivo: string; tipo: string }>
  ) => {
    return await apiCar.post(`${RESOURCE_DEALS}/v2/${numPedido}/documentos`, {
      id: id,
      arquivos: arquivos,
      observacao: observacao,
    });
  },
  ReprocessDocs: async (numPedido: string, ids: number[]) => {
    return await apiCar.put(
      `${RESOURCE_DEALS}/v2/${numPedido}/documentos/reprocessar`,
      { ids }
    );
  },
  DeleteDoc: async (numPedido: string, id: number) => {
    return await apiCar.delete(
      `${RESOURCE_DEALS}/${numPedido}/documentos/${id}/excluir`,
      { data: { foo: 'bar' } }
    );
  },
  SetDocObs: async (numPedido: string, id: number, observacao: string) => {
    return await apiCar.post(
      `${RESOURCE_DEALS}/${numPedido}/documentos/observacao`,
      {
        id: id,
        observacao: observacao,
      }
    );
  },
  ApprovalRequest: async (numPedido: string) => {
    return await apiCar.put(
      `${RESOURCE_DEALS}/${numPedido}/documentos/aprovar`,
      {}
    );
  },
  GetTicketBarCode: async (cod: string | number) => {
    return await apiCar.get(`${RESOURCE_DEALS}/proposta/${cod}/boleto`, {
      data: {},
    });
  },
  GetDataUltimoEnvioContratoAssinatura: async (
    codigoProposta: string | number
  ) => {
    return await apiCar.get(
      `${RESOURCE_DEALS}/dataenviocontrato/${codigoProposta}`,
      {
        data: {},
      }
    );
  },
  RealizarAcao: async (
    numPedido: number,
    acao: string,
    codigo: number | null = 0,
    codigoProposta: number | null = null
  ) => {
    return await apiCar.post(`proposta/acao`, {
      pedidoId: numPedido,
      proposta: codigoProposta,
      codigo: codigo,
      acao: acao,
    });
  },
  GetVencimentos: async (numPedido: number) => {
    return await apiCar.post('sgs/pedido/vencimento/opcoesdias', {
      pedidoId: numPedido,
    });
  },
  SaveVencimento: async (numPedido: number, opcaoVencimento: Vencimento) => {
    return await apiCar.post('sgs/pedido/vencimento/altera', {
      pedidoId: numPedido,
      dataPrimeiroVencimento: opcaoVencimento.data,
      diaOpcaoVencimento: opcaoVencimento.diaOpcao,
    });
  },
  GetEtapasEmissaoContrato: async (numPedido: number) => {
    return await apiCar.get(
      `${RESOURCE_DEALS}/pedido/${numPedido}/checklist/etapa`,
      {
        data: {},
      }
    );
  },
  GetStatusDut: async (numPedido: number) => {
    return await apiCar.get(
      `${RESOURCE_DEALS}/pedido/${numPedido}/checklist/dut/status`,
      {
        data: {},
      }
    );
  },
  GetContrato: async (numPedido: number) => {
    return await apiCar.get(`proposta/contrato/${numPedido}`, { data: {} });
  },
  SetContratoAlterado: async (
    codigoProposta: number,
    numPedido: number,
    cpfCnpjCliente: number
  ) => {
    return await apiCar.post(`proposta/contrato-alterado`, {
      codigoProposta: codigoProposta,
      pedidoId: numPedido,
      clienteCpfCnpj: cpfCnpjCliente,
    });
  },
  GetStatusAtualizacaoDadosCliente: async (
    cpfCnpj: number,
    codigoProposta: number
  ) => {
    return await apiCar.get(
      `${RESOURCE_DEALS}/clientes/atualizado?clienteCpfCnpj=${cpfCnpj}&codigoProposta=${codigoProposta}`,
      {
        data: {},
      }
    );
  },
  GetStatusAtualizacaoVencimento: async (
    codigoProposta: number,
    pedidoId: number
  ) => {
    return await apiCar.get(
      `${RESOURCE_DEALS}/vencimento/atualizado?codigoProposta=${codigoProposta}&pedidoId=${pedidoId}`,
      {
        data: {},
      }
    );
  },
};
