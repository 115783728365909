import * as S from './styles';
import { useContext, useEffect, useState } from 'react';

import Andamento from 'assets/icons/predios.svg';
import * as CXT from 'context/globalContext';
import { Header } from 'components/Header';
import NavBar from 'components/NavBar';
import { currencyFormat, telMask } from 'utils/mask';

import TextBox from './TextBox';
import DocButton from './DocButton';
import DocList, { ListData, ListInfo } from './DocList';
import DocAnexPopUp from './DocAnexPopUp';
import DocAvalistPopUp from './DocAvalistPopUp';
import DocViewPopUp from './DocViewPopUp';
import DocNotePopUp from './DocNotePopUp';
import { Deals } from 'services/propostas';
import { toast } from 'react-toastify';
import DocSubmitButton from './DocSubmitButton';
import { PopUpAntiFraud } from './PopUpAntiFraud';
import { Person } from 'services/client';
import { PopUpDocsApproval } from 'pages/Client/components/PopUpDocsApproval';
import ContractIssuance from './Contract';

type emails = {
  email: string;
  preferencia: boolean;
  status: number;
};

type phones = {
  ddd: string;
  preferencia: boolean;
  status: number;
  telefone: string;
  tipo: any;
};

export interface AntiFraudData {
  open: boolean;
  emailAvalista: string | undefined;
  telefoneAvalista: string | undefined;
  nomeAvalista: string;
  isAvalista: boolean;
}

export default function Documents() {
  const CREDIT_APPROVAL_TAB = 0;
  const DOCS_APPROVAL_TAB = 1;
  const DEAL_TAB = 2;

  const [buttonsNavBar] = useState(['Sinosserra', 'Minhas Propostas']);
  const [linksNavBar] = useState(['/client', '/mydeals']);

  const globalContext = useContext(CXT.Context);
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');

  const [tabIndex, setTabIndex] = useState(DOCS_APPROVAL_TAB);

  const [selectedDocSubTitle, setSelectedDocSubTitle] = useState('');
  const [selectedDocObs, setSelectedDocObs] = useState('');
  const [selectedDoc, setSelectedDoc] = useState<any>('');
  const [selectedDocData, setSelectedDocData] = useState<ListData>();

  const [activeAnex, setActiveAnex] = useState(false);
  const [activeAvalist, setActiveAvalist] = useState(false);
  const [activeNote, setActiveNote] = useState(false);
  const [activeViewDoc, setActiveViewDoc] = useState(false);
  const [readMod, setReadMod] = useState(false);
  const [allData, setAllData] = useState<any | null>(null);

  const [popUpAntiFraud, setPopUpAntiFraud] = useState<AntiFraudData>({
    open: false,
    emailAvalista: '',
    telefoneAvalista: '',
    nomeAvalista: '',
    isAvalista: false,
  });

  const [phoneClientAndEmail, setPhoneClientAndEmail] = useState({
    ddd: '',
    phone: '',
    email: '',
  });

  const [status, setStatus] = useState({
    companyName: '',
    companyCity: '',
    dealDate: '',
    dealNumber: '',
    dealStatus: '',
    dealPag: '',
    carBrand: '',
    carModel: '',
    carPlate: '',
    carColor: '',
    finValue: '',
    finPercentual: '',
    finFees: '',
    finReturn: '',
    finName: '',
    docStatus: '',
  });

  const [listInfo, setListInfo] = useState<ListInfo>();
  const [listData, setListData] = useState<Array<ListData>>([]);

  async function searchForEmailAndPhone(orderDetailsData: any) {
    globalContext.setIsLoading(true);
    let responseGetClient: any;
    try {
      responseGetClient = await Person.ClientRequest(
        orderDetailsData.clienteCpfCnpj
      );
    } catch (error: any) {
      if (error?.response.status >= 400) {
        return 'Ops! Tivemos problemas ao buscar os dados do cliente';
      }
    }

    const arrayEmails = responseGetClient.data.data.emails as emails[];
    const emailCorrect = arrayEmails.filter(
      (posicao) => posicao.preferencia && posicao.status === 1
    )[0];
    const email = emailCorrect?.email;

    const arrayPhones = responseGetClient.data.data.telefones as phones[];
    const phoneCorrect = arrayPhones.filter(
      (posicao) => posicao.preferencia && posicao.status === 1
    )[0];
    const fullPhoneNotFormated = `${phoneCorrect?.ddd}-${phoneCorrect?.telefone}`;
    const phoneFull = fullPhoneNotFormated.split('-') as string[];
    const dddClient = `(${phoneFull[0]})`;

    if (phoneFull[1].length === 9) {
      const phone = `${phoneFull[1].substring(0, 5)}-${phoneFull[1].substring(
        5,
        9
      )}`;
      setPhoneClientAndEmail({
        ddd: dddClient,
        phone,
        email,
      });
      globalContext.setIsLoading(false);
    }

    if (phoneFull[1].length === 8) {
      const phone = `${phoneFull[1].substring(0, 4)}-${phoneFull[1].substring(
        4,
        9
      )}`;
      setPhoneClientAndEmail({
        ddd: dddClient,
        phone,
        email,
      });
    }
  }

  const FillStatus = () => {
    const numPedido = localStorage.getItem('numPedido');
    globalContext.setIsLoading(true);
    if (numPedido) {
      setAllData(null);
      Deals.GetDeal(numPedido)
        .then(async (response) => {
          const orderDetailsData = response.data.data;

          searchForEmailAndPhone(orderDetailsData);

          setAllData(orderDetailsData);

          if (buttonsNavBar.length < 3) {
            buttonsNavBar.push(`#${orderDetailsData.pedidoId}`);
            linksNavBar.push(`#${orderDetailsData.pedidoId}`);
          }

          if (orderDetailsData.propostaAcoes.match('#MODO_LEITURA#')) {
            setReadMod(true);
          }

          setStatus({
            companyName: orderDetailsData.nomeEmpresa,
            companyCity: orderDetailsData.cidadeEmpresa,
            dealDate: orderDetailsData.dataInclusaoPedido,
            dealNumber: orderDetailsData.pedidoId,
            dealStatus: orderDetailsData.situacaoDescricao,
            dealPag: orderDetailsData.situacaoPagamento,
            carBrand: orderDetailsData.veiculoMarca,
            carModel: orderDetailsData.veiculoModelo,
            carPlate: orderDetailsData.veiculoPlaca
              ? orderDetailsData.veiculoPlaca
              : orderDetailsData.veiculoChassi,
            carColor: orderDetailsData.veiculoCor,
            finValue: orderDetailsData.valorFinanciado,
            finPercentual: orderDetailsData.percentualEntradaEfetivo,
            finFees: orderDetailsData.percentualJuros,
            finReturn: orderDetailsData.percentualRetorno,
            finName: orderDetailsData.nomeFinanceira,
            docStatus: orderDetailsData.documentoStatus,
          });

          setListInfo({
            name: orderDetailsData.clienteNome,
            cpf: orderDetailsData.clienteCpfCnpj,
            prop: orderDetailsData.propostaCodigo,
            date: orderDetailsData.dataInclusaoPedido,
          });

          localStorage.setItem(
            'pedido_cpfcnpj',
            orderDetailsData.clienteCpfCnpj
          );

          setListData(orderDetailsData.documentos);
        })
        .catch((err) => {
          if (err.response.status >= 400 && err.response.status < 500)
            toast.error('Erro ao consultar o servidor.');
          else
            toast.error(
              'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
            );
        })
        .finally(() => {
          globalContext.setIsLoading(false);
        });
    }
  };

  const ActiveAnexPopUp = async (data: ListData) => {
    setSelectedDocData(data);
    // setActiveAnex(true);
    const contextoAvalista = data.contexto.toLowerCase().includes('avalista');
    let antifraudValidation =
      process.env.REACT_APP_FEATURE_ANTI_FRAUDE === 'ativo' &&
      (data.nome === 'CNH' || data.nome.toLocaleLowerCase() === 'selfie') &&
      Object.keys(userData).length !== 0 &&
      userData.permissao.includes('ONBOARDING_CAF');

    const envioAvalistaDesabilitado =
      process.env.REACT_APP_FEATURE_ANTI_FRAUDE_AVALISTA !== 'ativo';
    if (contextoAvalista && envioAvalistaDesabilitado) {
      antifraudValidation = false;
    }

    let emailAvalista: emails | undefined = undefined;
    let telefoneAvalista: phones | undefined = undefined;
    let nomeAvalista = '';

    if (contextoAvalista && !envioAvalistaDesabilitado) {
      try {
        const response = await Person.ClientRequest(allData.avalistaCpfcnpj);
        nomeAvalista = allData.avalistaNome;
        emailAvalista = response.data.data.emails.find(
          (e: any) => e.preferencia && e.status === 1
        );
        telefoneAvalista = response.data.data.telefones.find(
          (e: any) => e.preferencia && e.status === 1
        );
      } catch (e) {
        console.error(e);
        emailAvalista = undefined;
        telefoneAvalista = undefined;
      }
    } else {
      emailAvalista = undefined;
      telefoneAvalista = undefined;
    }

    if (telefoneAvalista == undefined) {
      telefoneAvalista = {
        ddd: '',
        telefone: '',
        preferencia: false,
        status: 2,
        tipo: '',
      };
    }
    if (
      antifraudValidation &&
      data.identifier !== null &&
      data.identifier !== '0'
    ) {
      setPopUpAntiFraud({
        open: true,
        telefoneAvalista: telMask(
          `${telefoneAvalista?.ddd}${telefoneAvalista?.telefone}`
        ),
        emailAvalista: emailAvalista?.email || '',
        nomeAvalista: nomeAvalista,
        isAvalista: contextoAvalista,
      });
    } else if (data.identifier === null || data.identifier === '0') {
      setActiveAvalist(true);
    } else {
      setActiveAnex(true);
    }
  };

  const ActiveNotePopUp = (data: ListData) => {
    globalContext.setIsLoading(true);

    Deals.GetDoc(status.dealNumber, data.id)
      .then((response) => {
        const aux = listData.findIndex((element) => element.id === data.id);
        setSelectedDocData(data);
        setSelectedDoc(response.data.data.url);
        setSelectedDocObs(listData[aux].parecer);
        setActiveNote(true);
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500)
          toast.error('Erro ao consultar o servidor.');
        else
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
      })
      .finally(() => globalContext.setIsLoading(false));
  };

  const ActiveViewPopUp = (data: ListData, parecer: string) => {
    globalContext.setIsLoading(true);

    Deals.GetDoc(status.dealNumber, data.id)
      .then((response) => {
        setSelectedDocData(data);
        setSelectedDocSubTitle(parecer);
        setSelectedDoc(response.data.data.url);
        setSelectedDocObs(response.data.data.observacao ?? '');
        setActiveViewDoc(true);
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500)
          toast.error('Erro ao consultar o servidor.');
        else
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
      })
      .finally(() => globalContext.setIsLoading(false));
  };

  const DeleteDoc = (data: ListData) => {
    globalContext.setIsLoading(true);
    DesactivePopUp();
    Deals.DeleteDoc(status.dealNumber, data.id)
      .then(() => {
        const newSet = new Set(globalContext.documentsOnApprovalQueue);
        newSet.delete(data.id);
        globalContext.setDocumentsOnApprovalQueue(newSet);
        toast.info('Apagado com Sucesso');
        globalContext.RefreshPage();
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500)
          toast.error('Erro ao consultar o servidor.');
        else
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
      })
      .finally(() => globalContext.setIsLoading(false));
  };

  const DesactivePopUp = () => {
    setActiveAnex(false);
    setActiveViewDoc(false);
    setActiveNote(false);
  };

  const showPopUp = () => {
    return (
      <>
        {popUpAntiFraud.open && (
          <PopUpAntiFraud
            popUpAntiFraud={popUpAntiFraud}
            setPopUpAntiFraud={setPopUpAntiFraud}
            dealNumber={status.dealNumber}
            nameClient={listInfo ? listInfo.name : ''}
            emailClient={phoneClientAndEmail.email}
            phoneClient={`${phoneClientAndEmail.ddd} ${phoneClientAndEmail.phone}`}
            orderDetailsData={allData}
          />
        )}
        <DocAnexPopUp
          data={selectedDocData}
          pedidoId={status.dealNumber}
          isActive={activeAnex}
          setIsActive={setActiveAnex}
        />
        <DocViewPopUp
          data={selectedDocData}
          subTitle={selectedDocSubTitle}
          pedidoId={status.dealNumber}
          obs={selectedDocObs}
          isActive={activeViewDoc}
          setIsActive={setActiveViewDoc}
          url={selectedDoc}
          deleteDoc={DeleteDoc}
          readMod={readMod}
        />
        <DocNotePopUp
          data={selectedDocData}
          pedidoId={status.dealNumber}
          isActive={activeNote}
          setIsActive={setActiveNote}
          url={selectedDoc}
          obsNote={selectedDocObs}
        />
        <DocAvalistPopUp
          data={selectedDocData}
          isActive={activeAvalist}
          setIsActive={setActiveAvalist}
        />
      </>
    );
  };

  const [docsApprovalData, setDocsApprovalData] = useState(false);

  function enablePopUpApprovalDocs(): boolean {
    const listStatus = ['SENDING'];
    const solicitarAprovacaoHabilitado =
      status.docStatus === 'EM_PROCESSO_ENVIO' && !readMod;

    return listData.some((item) => {
      const naoEnviado = !globalContext.documentsOnApprovalQueue.has(item.id);
      const ccbAssinada = item.nome.includes('CCB Assinada');
      const ccbAssinadaClient = item.nome.includes('CCB Assinada - Cliente');
      const propostaSpf = item.nome.includes('Proposta SPF');
      const propostaSpfClient = item.nome.includes('Proposta SPF');

      return (
        listStatus.includes(item.status) &&
        naoEnviado &&
        !propostaSpf &&
        !ccbAssinada &&
        !ccbAssinadaClient &&
        !propostaSpfClient &&
        solicitarAprovacaoHabilitado
      );
    });
  }

  useEffect(() => {
    FillStatus();
    localStorage.removeItem('auto_go_back');
  }, []);

  const sendApprovalDocs = () => {
    globalContext.setIsLoading(true);
    Deals.ApprovalRequest(status.dealNumber)
      .then(() => {
        listData.forEach((item) => {
          const ids = new Set<number>();
          ids.add(item.id);
          globalContext.setDocumentsOnApprovalQueue(ids);
        });
        toast.info('Solicitação Realizada com Sucesso');
        globalContext.RefreshPage();
        globalContext.setIsLoading(false);
        setDocsApprovalData(false);
      })
      .catch((err) => {
        if (err.response.status >= 400 && err.response.status < 500)
          toast.error('Erro ao consultar o servidor.');
        else
          toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
      })
      .finally(() => globalContext.setIsLoading(false));
  };

  return (
    <S.Wrapper>
      {enablePopUpApprovalDocs() &&
        status.docStatus === 'EM_PROCESSO_ENVIO' && (
          <PopUpDocsApproval
            dealNumber={status.dealNumber}
            isActive={docsApprovalData}
            setIsActive={setDocsApprovalData}
          />
        )}
      <Header onRefreshClick={FillStatus} />
      <S.NavBarBox>
        <NavBar buttons={buttonsNavBar} links={linksNavBar} />
      </S.NavBarBox>
      <S.BodyBox>
        <S.DataBox>
          <S.DataBoxTitle className="merge">
            <S.ImgTitle className="mergeRow" src={Andamento} />
            <span>{status.companyName}</span>
            <label>{status.companyCity}</label>
          </S.DataBoxTitle>
          <S.HorizontalBar className="merge" />
          <TextBox className="merge" variant>
            STATUS DA PROPOSTA
          </TextBox>
          <TextBox className="merge">{status.dealStatus}</TextBox>
          <TextBox className="merge">{status.dealPag}</TextBox>
          <TextBox title="DATA INCLUSÃO">{status.dealDate}</TextBox>
          <TextBox title="Nº PEDIDO ">#{status.dealNumber}</TextBox>

          <TextBox className="merge" variant>
            VEÍCULO
          </TextBox>
          <TextBox title="MARCA">{status.carBrand}</TextBox>
          <TextBox title="MODELO">
            {status.carModel.length > 14
              ? `${status.carModel.substring(0, 13)}...`
              : status.carModel}
          </TextBox>
          <TextBox title="PLACA / CHASSI">{status.carPlate}</TextBox>
          <TextBox title="COR">{status.carColor}</TextBox>

          <TextBox className="merge" variant>
            NEGOCIAÇÃO
          </TextBox>
          <TextBox title="FINANCIADO">
            R$ {currencyFormat(Number(status.finValue + '00'), true)}
          </TextBox>
          <TextBox title="% FINANCIADO">{status.finPercentual}%</TextBox>
          <TextBox title="% JUROS">
            {Number(status.finFees).toFixed(2)}%
          </TextBox>
          <TextBox title="% RETORNO">
            {Number(status.finReturn).toFixed(2)}%
          </TextBox>
          <TextBox className="merge" title="FINANCEIRA">
            {status.finName}
          </TextBox>
        </S.DataBox>
        <S.DocBox>
          <S.DocButtonBox>
            <DocButton
              className={`squared ${
                tabIndex === CREDIT_APPROVAL_TAB ? 'selected' : ''
              }`}
              disabled={true}
              onClick={() => setTabIndex(CREDIT_APPROVAL_TAB)}
            >
              Aprovação de crédito <span>(em desenvolvimento)</span>
            </DocButton>
            <DocButton
              className={`squared ${
                tabIndex === DOCS_APPROVAL_TAB ? 'selected' : ''
              }`}
              onClick={() => setTabIndex(DOCS_APPROVAL_TAB)}
            >
              Aprovação da documentação
            </DocButton>
            <DocButton
              className={`squared ${tabIndex === DEAL_TAB ? 'selected' : ''}`}
              onClick={() => setTabIndex(DEAL_TAB)}
            >
              Emissão de contrato
            </DocButton>
          </S.DocButtonBox>

          <DocList
            show={tabIndex === DOCS_APPROVAL_TAB}
            activeAnexPopUp={ActiveAnexPopUp}
            activeNotePopUp={ActiveNotePopUp}
            activeViewPopUp={ActiveViewPopUp}
            dealNumber={status.dealNumber}
            listInfo={listInfo ?? listInfo}
            listData={listData ?? listData}
            docStatus={status.docStatus}
            readMod={readMod}
            enablePopUpApprovalDocs={enablePopUpApprovalDocs}
            setDocsApprovalData={setDocsApprovalData}
          />

          <ContractIssuance
            show={tabIndex == DEAL_TAB}
            proposta={allData}
            emailCliente={phoneClientAndEmail.email}
            telefoneCliente={`${phoneClientAndEmail.ddd} ${phoneClientAndEmail.phone}`}
            reloadProposta={FillStatus}
            goToAprovacaoTab={() => setTabIndex(CREDIT_APPROVAL_TAB)}
          ></ContractIssuance>
        </S.DocBox>
        <S.FooterBox>
          <DocButton
            icone
            sumirOnLoad
            onClick={() => {
              history.back();
            }}
            className="Transparent slim"
          >
            VOLTAR
          </DocButton>
          <DocSubmitButton
            disabled={status.docStatus !== 'EM_PROCESSO_ENVIO' || readMod}
            onClick={() => {
              if (enablePopUpApprovalDocs()) {
                sendApprovalDocs();
              }
            }}
          >
            SOLICITAR APROVAÇÃO
          </DocSubmitButton>
        </S.FooterBox>
      </S.BodyBox>
      {showPopUp()}
    </S.Wrapper>
  );
}
