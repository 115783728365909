import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik, FormikProps, FormikTouched } from 'formik';
import { FaUser } from 'react-icons/fa';
import { IoMdBriefcase } from 'react-icons/io';
import { VscHome } from 'react-icons/vsc';
import { Divider } from '@mui/material';
import { toast } from 'react-toastify';

import * as CXT from 'context/globalContext';
import Button from 'components/Button';
import MaskedInput from 'components/MaskedInput';
import { cpfMask, unMask } from 'utils/mask';
import { findData } from 'services/findCEP';
import { Person } from 'services/client';
import { Register } from 'services/register';
import { MaritalStatus } from 'services/maritalStatus';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import convertDataToSubmit from '../../Register/utils/convertDataToSubmit';
import { ContainerWithInformation } from '../../Register/components/ComponentWithInformation';
import { InputsAndSelectsOfBasicInformation } from '../../Register/components/InputsAndSelectsOfBasicInformation';
import { InputsForInformationAddress } from '../../Register/components/InputsForInformationAddress';
import { InputsAndSelectsOfProfissionalInformation } from '../../Register/components/InputsAndSelectsOfProfissionalInformation';
import { ButtonsForm } from '../../Register/components/ButtonsForm';
import { FormPropsType } from '../../Register/utils/types/FormPropType/FormPropType';
import {
  returnsWithCapitalLettersAndWithoutAccent,
  removeWhiteSpace,
} from '../../Register/utils/formFunctions/returnsWithCapitalLettersAndWithoutAccent';
import { initialData } from '../../Register/utils/initialData/intialValuesForForm';
import { findEnderecoIndex } from '../../Register/utils/convertDataToSubmit';

import * as S from './styles';
import {
  EstadoCivilProps,
  ProfissaoProps,
  TipoOcupacaoProps,
} from 'pages/Register/utils/personProps';
import { cloneDeep, each, find, size, some } from 'lodash';
import { validation } from 'pages/Register/utils/validation/validation';

Bugsnag.start({
  apiKey: '00621c4c68a1b2bd34e7458c0f8d29bc',
  plugins: [new BugsnagPluginReact()],
});

type PessoaFisicaFormProps = {
  customerCpf: string;
  municipios: any[];
  submitButtonLabel?: string;
  stopLoadingOnFinish?: boolean;
  finishCallback: (registerResponse: any) => void;
  missingCustomerDataCallback?: () => void;
};

export default function PessoaFisicaForm({
  customerCpf,
  municipios,
  stopLoadingOnFinish = true,
  submitButtonLabel,
  finishCallback,
  missingCustomerDataCallback,
}: PessoaFisicaFormProps) {
  const globalContext = useContext(CXT.Context);
  const [customer, setCustomer] = useState<any>({});
  const [avalista, setAvalista] = useState<any>({});

  const [addingAvalista, setAddingAvalista] = useState(false);
  const [avalistaCpfCnpj, setAvalistaCpfCnpj] = useState('');

  const [comprovantes, setComprovantes] = useState<ProfissaoProps[]>([]);
  const [profissoes, setProfissoes] = useState<ProfissaoProps[]>([]);
  const [estadosCivis, setEstadosCivis] = useState<EstadoCivilProps[]>([]);
  const [ocupacoes, setOcupationList] = useState<TipoOcupacaoProps[]>([]);
  const [tipoOcupacao, setTipoOcupacao] = useState(0);
  const [ocupacoesTags, setOcupacoesTags] = useState('');
  const [tipoOcupacaoAvalista, setTipoOcupacaoAvalista] = useState(0);
  const [ocupacoesTagsAvalista, setOcupacoesTagsAvalista] = useState('');
  const [dataAdmissaoInvalidaMsg, setDataAdmissaoInvalidaMsg] = useState('');

  const [successForm, setSuccessForm] = useState({} as FormPropsType);
  const [errorsForm, setErrorsForm] = useState({} as FormPropsType);
  const [formSubmited, setFormSubmited] = useState(false);

  const [initialValues, setInitialValues] = useState<FormPropsType>(
    initialData(undefined, -1, undefined, () => '', [])
  );

  const updateSuccessForm = (data: any) =>
    setSuccessForm({ ...data, avalista: successForm.avalista });
  const updateErrorsForm = (data: any) =>
    setErrorsForm({ ...data, avalista: errorsForm.avalista });
  const updateSuccessFormAvalista = (data: any) =>
    setSuccessForm({ ...successForm, avalista: data });
  const updateErrorsFormAvalista = (data: any) =>
    setErrorsForm({
      ...errorsForm,
      avalista: data,
    });

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    loadPersonData();
  }, [customerCpf]);

  useEffect(() => {
    setTipoOcupacao(customer?.referenciasProfissionais?.[0]?.tipoOcupacao || 0);
    setTipoOcupacaoAvalista(
      avalista?.referenciasProfissionais?.[0]?.tipoOcupacao || 0
    );
  }, [customer, avalista]);

  useEffect(() => {
    if (tipoOcupacao && ocupacoes) {
      const found = find(
        ocupacoes,
        (it) => Number(it.codigo) === Number(tipoOcupacao)
      );
      if (found) {
        setOcupacoesTags(found.tagValidacao);
      }
    }
  }, [tipoOcupacao, ocupacoes]);

  useEffect(() => {
    if (tipoOcupacaoAvalista && ocupacoes) {
      const found = find(
        ocupacoes,
        (it) => Number(it.codigo) === Number(tipoOcupacaoAvalista)
      );
      if (found) {
        setOcupacoesTagsAvalista(found.tagValidacao);
      }
    }
  }, [tipoOcupacaoAvalista, ocupacoes]);

  // useEffect(() => {
  //   const fullDateOfBirth = initialValues.aniversario ?? 0;

  //   const birthdayMonth = Number(fullDateOfBirth.substring(3, 5));
  //   const yearOfBirth = Number(fullDateOfBirth.substring(6, 10));
  //   const yearOfValidation = yearOfBirth + 16;

  //   const fullDateValidationAdmission =
  //     birthdayMonth >= 10
  //       ? `${birthdayMonth}/${yearOfValidation}`
  //       : `0${birthdayMonth}/${yearOfValidation}`;

  //   setDataAdmissaoInvalidaMsg(
  //     `Data inferior à ${fullDateValidationAdmission}`
  //   );
  //   //ID-327 -> para trocar a mensagem do input basta comentar ou excluir
  //   //esta linha de número 166 e trocar o let da variável messageErrorValidationDateAdmission para const,
  //   // e atribuir a ela o valor da mensagem. Caso a mensagem seja definitiva, favor alterar a condição do input no atributo ou propriedade ErrorMessage
  //   //retornando apenas a variável com a condicional de que se caso ela existir exiba-a, ou seja(variavel && variavel).
  // }, [initialValues]);

  async function getOptions() {
    MaritalStatus.Get()
      .then((response) => {
        setEstadosCivis(response.data.data);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        toast.error('Erro ao carregar os estados civis');
      });

    findData
      .GetTipoOcupação()
      .then((response) => {
        setOcupationList(response.data.data);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        setOcupationList([
          {
            codigo: '1',
            descricao: 'Funcionário de Iniciativa Privada',
            abreviacao: '1',
            tagValidacao:
              '#renda##rp_nome_empresa##rp_municipio##rp_telefone##rp_admissao##profissao#',
          },
          {
            codigo: '10',
            descricao: 'Estudante',
            abreviacao: '10',
            tagValidacao: '#renda#',
          },
        ]);
      });

    Person.JobsRequest()
      .then((response: any) => {
        setProfissoes(response.data.data);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        toast.error('Erro ao carregar as profissões');
      });

    Person.ComprovantRequest()
      .then((response: any) => {
        setComprovantes(response.data.data);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        toast.error('Erro ao carregar os tipos de comprovantes');
      });
  }

  const loadAvalistaData = (
    cpfCnpjAvalista: string,
    formData: FormPropsType
  ) => {
    globalContext.setIsLoading(true);
    const unmaskedCpf = unMask(cpfCnpjAvalista, '\\.|-');
    Person.ClientRequest(unmaskedCpf)
      .then((response) => {
        const avalista = response?.data?.data;
        setAvalista(avalista);

        const enderecoAvalistaIndex = findEnderecoIndex(avalista?.enderecos);

        let avalistaPhone;

        if (avalista.telefones) {
          avalistaPhone = avalista.telefones.find((item: any) => {
            return item.status === 1 && item.preferencia === true;
          });
        }

        const getAvalistaEmail = () => {
          let aux = '';

          avalista?.emails?.forEach(
            (value: {
              email: string;
              preferencia: boolean;
              status: number;
            }) => {
              if (value.preferencia === true && value.status === 1) {
                aux = value.email;
              }
            }
          );

          return aux;
        };

        formData.avalista = initialData(
          avalista,
          enderecoAvalistaIndex,
          avalistaPhone,
          getAvalistaEmail,
          municipios
        );

        setInitialValues(formData);
      })
      .catch(() => {
        toast.error('Não foi possível encontrar pessoa com o CPF informado');
      })
      .finally(() => {
        globalContext.setIsLoading(false);
      });
  };

  const loadPersonData = () => {
    globalContext.setIsLoading(true);
    const unmaskedCpf = unMask(customerCpf, '\\.|-');

    Person.ClientRequest(unmaskedCpf)
      .then(async (response) => {
        const foundPerson = response?.data?.data;

        setCustomer(foundPerson);
        localStorage.setItem('client', JSON.stringify(foundPerson));

        let phone: any;

        try {
          if (foundPerson.telefones) {
            phone = foundPerson.telefones.find((item: any) => {
              return item.status === 1 && item.preferencia === true;
            });
          }
        } catch {
          if (missingCustomerDataCallback) {
            missingCustomerDataCallback();
          }
        }

        const getEmail = () => {
          let aux = '';

          foundPerson?.emails?.forEach(
            (value: {
              email: string;
              preferencia: boolean;
              status: number;
            }) => {
              if (value.preferencia === true && value.status === 1) {
                aux = value.email;
              }
            }
          );

          return aux;
        };

        const enderecoIndex = findEnderecoIndex(foundPerson?.enderecos);

        const formData = initialData(
          foundPerson,
          enderecoIndex,
          phone,
          getEmail,
          municipios
        ) as FormPropsType;

        if (
          size(foundPerson.avalista) > 0 &&
          some(foundPerson.avalista, (it) => it.statusRegistro === 1)
        ) {
          const aval = find(
            foundPerson.avalista,
            (it) => it.statusRegistro === 1
          );
          const cpf = new String(aval.cpfCnpjAvalista).padStart(11, '0');
          loadAvalistaData(cpf, formData);
        } else {
          setInitialValues(formData);
          globalContext.setIsLoading(false);
        }
      })
      .catch((error) => {
        globalContext.setIsLoading(false);
        if (error.response?.status === 404) {
          return toast.error(
            'Cliente não encontrado com esse CPF. Verifique os dados digitados e tente novamente'
          );
        }

        if (error.response?.status === 500) {
          return toast.error(
            'Ops! Tivemos um problema ao prosseguir com a sua solicitação. ' +
              'Tente novamente mais tarde e se o problema persistir contate o suporte!'
          );
        }

        return toast.error(
          'Não foi possível prosseguir com a solicitação. Faça o Login novamente.'
        );
      });
  };

  const abortaAdicaoAvalistaELimpaDados = () => {
    setAddingAvalista(false);

    const newCustomer = cloneDeep(customer);
    each(newCustomer.avalista, (avalista) => {
      if (avalista.codPessoaAvalista === initialValues.avalista?.id) {
        avalista.statusRegistro = 2;
      }
    });
    setCustomer(newCustomer);
    localStorage.setItem('client', JSON.stringify(newCustomer));

    const newInitialValues = cloneDeep(initialValues);
    newInitialValues.avalista = undefined;
    setInitialValues(newInitialValues);
  };

  const setAvalistaFieldValue =
    (
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) =>
    (field: string, value: any, shouldValidate?: boolean | undefined) => {
      setFieldValue('avalista.' + field, value, shouldValidate);
    };

  const normalize = (value: string) => {
    return removeWhiteSpace(returnsWithCapitalLettersAndWithoutAccent(value));
  };

  const findMunicipio = (cidade: string, uf: string) => {
    return find(
      municipios,
      (it) =>
        normalize(it.descricao) === normalize(cidade) &&
        normalize(it.codigoEstado) === normalize(uf)
    );
  };

  const findItem = (
    list: EstadoCivilProps[] | ProfissaoProps[],
    value: string
  ): EstadoCivilProps | ProfissaoProps => {
    const found = find(list, (posicao) => posicao.descricao === value);
    if (!found) throw new Error('Item not found');
    return found;
  };

  const submitPerson = (person: any, formData: FormPropsType) => {
    return new Promise((resolve, error) => {
      const cliente = findMunicipio(formData.cidade, formData.uf);
      const empresa = findMunicipio(formData.cidadeEmpresa, formData.ufEmpresa);
      const municipioEmpresaId = empresa?.id;

      const dataMunicipio = {
        id: cliente.id,
        descricao: returnsWithCapitalLettersAndWithoutAccent(cliente.descricao),
        codigoEstado: normalize(cliente.codigoEstado),
      };

      const estadoCivil = findItem(estadosCivis, formData.estadoCivil);
      const profissao = findItem(profissoes, formData.profissao);
      const comprovacao = findItem(comprovantes, formData.comprovacao);

      const updatedJson = convertDataToSubmit(
        person,
        formData,
        estadoCivil,
        profissao,
        comprovacao,
        dataMunicipio,
        municipioEmpresaId
      );

      const postData = {
        messages: null,
        data: updatedJson,
      };

      Register.Post(postData)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          Bugsnag.notify(err);
          error();
        });
    });
  };

  const validateCidadeUf = (
    formData: FormPropsType,
    validationTags: string
  ) => {
    const municipioCliente = findMunicipio(formData.cidade, formData.uf);
    if (!municipioCliente) {
      formData.cidade = '';
      formData.uf = '';
      toast.error('Endereço Pessoal: Cidade ou UF inexistente');
    }

    const municipioEmpresa = findMunicipio(
      formData.cidadeEmpresa,
      formData.ufEmpresa
    );
    if (validationTags.match('rp_municipio')) {
      if (!municipioEmpresa) {
        formData.cidadeEmpresa = '';
        formData.ufEmpresa = '';
        toast.error('Referencia Profissional: Cidade ou UF inexistente');
      }
    }
  };

  const isFormValid = (formData: FormPropsType) => {
    validateCidadeUf(formData, ocupacoesTags);
    const resultado = validation(
      formData,
      estadosCivis,
      ocupacoes.map((posicao) => Number(posicao.codigo)),
      comprovantes,
      ocupacoesTags,
      profissoes
    );

    let resultadoAvalista;
    if (formData.avalista) {
      validateCidadeUf(formData.avalista, ocupacoesTagsAvalista);
      validation(
        formData.avalista,
        estadosCivis,
        ocupacoes.map((posicao) => Number(posicao.codigo)),
        comprovantes,
        ocupacoesTagsAvalista,
        profissoes
      );
    }

    // index 0 - Success fields
    // index 1 - Error fields
    // index 2 - status

    setSuccessForm({
      ...resultado[0],
      avalista: resultadoAvalista ? resultadoAvalista[0] : undefined,
    });
    setErrorsForm({
      ...resultado[1],
      avalista: resultadoAvalista ? resultadoAvalista[1] : undefined,
    });

    if (resultado[2] && (!resultadoAvalista || resultadoAvalista[2])) {
      globalContext.setIsLoading(true);
      return true;
    }
    return false;
  };

  const handleSubmit = (formData: FormPropsType) => {
    if (!isFormValid(formData)) {
      return;
    }

    setFormSubmited(true);

    if (formData.avalista) {
      submitPerson(avalista, formData.avalista)
        .then(() => {
          submitPerson(customer, formData)
            .then((response) => {
              if (stopLoadingOnFinish) {
                toast.success('Salvo com sucesso!');
              }
              finishCallback(response);
            })
            .catch(() => {
              toast.error('Erro ao salvar o cliente');
            })
            .finally(() => {
              if (stopLoadingOnFinish) {
                globalContext.setIsLoading(false);
              }
            });
        })
        .catch(() => {
          toast.error('Erro ao salvar o avalista');
          globalContext.setIsLoading(false);
        });
    } else {
      submitPerson(customer, formData)
        .then((response) => {
          if (stopLoadingOnFinish) {
            toast.success('Salvo com sucesso!');
          }
          finishCallback(response);
        })
        .catch(() => {
          toast.error('Erro ao salvar o cliente');
        })
        .finally(() => {
          if (stopLoadingOnFinish) {
            globalContext.setIsLoading(false);
          }
        });
    }
  };

  const validOnChangeAfterError =
    (
      updateSuccessForm: (data: any) => void,
      updateErrorsForm: (data: any) => void,
      ocupacaoValidationTags: string
    ) =>
    (
      campSelect: string,
      eventTargetValue: string,
      values: FormPropsType,
      listaDeProfissao: ProfissaoProps[]
    ) => {
      console.log('validOnChangeAfterError');
      if (
        formSubmited &&
        eventTargetValue !== undefined &&
        ocupacaoValidationTags
      ) {
        console.log('validOnChangeAfterError IN');
        const array = Object.entries(values);
        const arrayWithIndexOneOfArray = array.map((posicao) => posicao[0]);

        if (campSelect === 'mesAnoAdmissao') {
          const newFormatForCampSelect = eventTargetValue.replace('/', '');
          eventTargetValue = newFormatForCampSelect;
        }

        const indexForChange = arrayWithIndexOneOfArray.indexOf(campSelect);
        array[indexForChange][1] = eventTargetValue;

        const changeValueInArray = Object.fromEntries(array);
        const newObjectWithValues = changeValueInArray as FormPropsType;

        values = newObjectWithValues;

        const valid = validation(
          values,
          estadosCivis,
          ocupacoes.map((posicao) => Number(posicao.codigo)),
          comprovantes,
          ocupacaoValidationTags,
          listaDeProfissao
        );

        updateSuccessForm(valid[0]);

        updateErrorsForm(valid[1]);
      }
    };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: FormPropsType) => handleSubmit(values)}
      enableReinitialize={true}
    >
      {({ touched, values, setFieldValue }: FormikProps<FormPropsType>) => (
        <Form>
          <S.Content>
            <S.Left>
              <S.BasicInfo>
                <ContainerWithInformation
                  Icon={FaUser}
                  text={'Informações Básicas do Cliente'}
                  cpf={values.cpf}
                />
                <InputsAndSelectsOfBasicInformation
                  isModifying
                  values={values}
                  errorsForm={errorsForm}
                  successForm={successForm}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  maritalStatusList={estadosCivis}
                />
              </S.BasicInfo>
              <S.AddressContact>
                <ContainerWithInformation
                  Icon={VscHome}
                  text={'Endereço e contato'}
                />

                <InputsForInformationAddress
                  values={values}
                  maritalStatusList={estadosCivis}
                  listaComprovantes={comprovantes}
                  errorsForm={errorsForm}
                  successForm={successForm}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  validOnChangeAfterError={validOnChangeAfterError(
                    updateSuccessForm,
                    updateErrorsForm,
                    ocupacoesTags
                  )}
                  listaProfissoes={profissoes}
                  formSubmited={formSubmited}
                  ocupationCurrentTagValidation={ocupacoesTags}
                  ocupationList={ocupacoes}
                />
              </S.AddressContact>
            </S.Left>
            <S.Right>
              <S.ProfessionalInfo>
                <ContainerWithInformation
                  Icon={IoMdBriefcase}
                  text={'Informações Profissionais'}
                />
                <InputsAndSelectsOfProfissionalInformation
                  values={values}
                  maritalStatusList={estadosCivis}
                  errorsForm={errorsForm}
                  successForm={successForm}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  validOnChangeAfterError={validOnChangeAfterError(
                    updateSuccessForm,
                    updateErrorsForm,
                    ocupacoesTags
                  )}
                  listaProfissoes={profissoes}
                  formSubmited={formSubmited}
                  ocupationCurrentTagValidation={ocupacoesTags}
                  ocupationList={ocupacoes}
                  setErrorsForm={updateErrorsForm}
                  listaComprovantes={comprovantes}
                  messageErrorValidationDateAdmission={dataAdmissaoInvalidaMsg}
                  setFormSubmited={setFormSubmited}
                  setTipoOcupacao={setTipoOcupacao}
                />
              </S.ProfessionalInfo>
            </S.Right>
          </S.Content>

          <S.HContainer>
            <Divider />
          </S.HContainer>
          <S.HContainer>
            {!addingAvalista && !values.avalista && (
              <Button
                onClick={() => setAddingAvalista(true)}
                width="200px"
                marginTop="16px"
                buttonTheme="secondary"
                disabled={globalContext.isLoading}
              >
                INCLUIR AVALISTA
              </Button>
            )}
            {(addingAvalista || values.avalista) && (
              <Button
                onClick={abortaAdicaoAvalistaELimpaDados}
                width="300px"
                marginTop="16px"
                type="button"
                buttonTheme="secondary"
                disabled={globalContext.isLoading}
              >
                Remover avalista
              </Button>
            )}
          </S.HContainer>
          {addingAvalista && !values.avalista && (
            <S.HContainerAround>
              <MaskedInput
                id="cpfCnpj"
                name="cpfCnpj"
                title="CPF/CNPJ do Avalista"
                value={avalistaCpfCnpj}
                onChange={(event) => {
                  setAvalistaCpfCnpj(cpfMask(event.target.value));
                }}
                placeholder="Digite o CPF ou CNPJ"
                MaskType={'cpf'}
                width="300px"
              />
              <Button
                onClick={() => loadAvalistaData(avalistaCpfCnpj, values)}
                loading={globalContext.isLoading}
                disabled={size(avalistaCpfCnpj) < 14}
                width="150px"
                type="button"
              >
                Carregar avalista
              </Button>
            </S.HContainerAround>
          )}
          {values.avalista && (
            <S.Content>
              <S.Left>
                <S.BasicInfo>
                  <ContainerWithInformation
                    Icon={FaUser}
                    text={'Informações Básicas do Avalista'}
                    cpf={values.avalista?.cpf}
                  />
                  <InputsAndSelectsOfBasicInformation
                    isModifying
                    values={values.avalista}
                    errorsForm={errorsForm?.avalista}
                    successForm={successForm?.avalista}
                    touched={
                      (touched.avalista ||
                        {}) as unknown as FormikTouched<FormPropsType>
                    }
                    setFieldValue={setAvalistaFieldValue(setFieldValue)}
                    maritalStatusList={estadosCivis}
                  />
                </S.BasicInfo>
                <S.AddressContact>
                  <ContainerWithInformation
                    Icon={VscHome}
                    text={'Endereço e contato'}
                  />

                  <InputsForInformationAddress
                    values={values.avalista}
                    maritalStatusList={estadosCivis}
                    listaComprovantes={comprovantes}
                    errorsForm={errorsForm.avalista}
                    successForm={successForm.avalista}
                    touched={
                      (touched.avalista ||
                        {}) as unknown as FormikTouched<FormPropsType>
                    }
                    setFieldValue={setAvalistaFieldValue(setFieldValue)}
                    validOnChangeAfterError={validOnChangeAfterError(
                      updateSuccessFormAvalista,
                      updateErrorsFormAvalista,
                      ocupacoesTagsAvalista
                    )}
                    listaProfissoes={profissoes}
                    formSubmited={formSubmited}
                    ocupationCurrentTagValidation={ocupacoesTagsAvalista}
                    ocupationList={ocupacoes}
                  />
                </S.AddressContact>
              </S.Left>
              <S.Right>
                <S.ProfessionalInfo>
                  <ContainerWithInformation
                    Icon={IoMdBriefcase}
                    text={'Informações Profissionais'}
                  />
                  <InputsAndSelectsOfProfissionalInformation
                    values={values.avalista}
                    maritalStatusList={estadosCivis}
                    errorsForm={errorsForm.avalista}
                    successForm={successForm.avalista}
                    touched={
                      (touched.avalista ||
                        {}) as unknown as FormikTouched<FormPropsType>
                    }
                    setFieldValue={setAvalistaFieldValue(setFieldValue)}
                    validOnChangeAfterError={validOnChangeAfterError(
                      updateSuccessFormAvalista,
                      updateErrorsFormAvalista,
                      ocupacoesTagsAvalista
                    )}
                    listaProfissoes={profissoes}
                    formSubmited={formSubmited}
                    ocupationCurrentTagValidation={ocupacoesTagsAvalista}
                    ocupationList={ocupacoes}
                    setErrorsForm={updateErrorsFormAvalista}
                    listaComprovantes={comprovantes}
                    messageErrorValidationDateAdmission={
                      dataAdmissaoInvalidaMsg
                    }
                    setFormSubmited={setFormSubmited}
                    setTipoOcupacao={setTipoOcupacaoAvalista}
                  />
                </S.ProfessionalInfo>
              </S.Right>
            </S.Content>
          )}
          <S.HContainer>
            {globalContext.isLoading ? (
              ''
            ) : (
              <ButtonsForm isModifying submitButtonLabel={submitButtonLabel} />
            )}
          </S.HContainer>
        </Form>
      )}
    </Formik>
  );
}
