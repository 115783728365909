import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Bugsnag from '@bugsnag/js';
import { padStart, size } from 'lodash';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import * as S from './styles';
import * as CXT from 'context/globalContext';
import 'firebase/analytics';
import { cpfMask, unMask } from 'utils/mask';
import { Header } from 'components/Header';
import { findData } from 'services/findCEP';
import Button from 'components/Button';
import MaskedInput from 'components/MaskedInput';

import { PopUpDanger } from '../Register/components/PopUpDanger';
import { PopUpWarning } from '../Register/PopUpWarning';

import { TitleWrapperRegisterUpdate } from './components/TitleWrapperRegisterUpdate';

import PessoaFisicaForm from './pessoa-fisica';

Bugsnag.start({
  apiKey: '00621c4c68a1b2bd34e7458c0f8d29bc',
  plugins: [new BugsnagPluginReact()],
});

export default function ClientRegisterUpdate() {
  const history = useHistory();
  const globalContext = useContext(CXT.Context);

  const [identifier, setIdentifier] = useState<string>('');
  const [identifierType, setIdentifierType] = useState<
    'CPF' | 'CNPJ' | undefined
  >(undefined);

  const [municipios, setMunicipios] = useState<any[]>([]);
  const [popUpDangerActive, setPopUpDangerActive] = useState<boolean>(false);

  useEffect(() => {
    getOptions();

    const cpfCnpjCliente = localStorage.getItem('pedido_cpfcnpj');
    const isComingFromOtherPage = localStorage.getItem('auto_go_back');
    if (cpfCnpjCliente && isComingFromOtherPage) {
      triggerLoadData(cpfCnpjCliente);
    }

    return () => {
      localStorage.removeItem('pedido_cpfcnpj');
      localStorage.removeItem('auto_go_back');
    };
  }, []);

  async function getOptions() {
    findData
      .GetMunicipio()
      .then((response) => {
        setMunicipios(response.data.data);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        toast.error('Erro ao carregar os municípios');
      });
  }

  const onFinish = () => {
    if (localStorage.getItem('auto_go_back')) {
      history.goBack();
    }
  };

  const triggerLoadData = (cpfCnpj?: string) => {
    if (!cpfCnpj) {
      cpfCnpj = identifier;
    }

    if (cpfCnpj) {
      let length = size(unMask(cpfCnpj, '\\.|-'));
      if (length < 11) {
        cpfCnpj = padStart(cpfCnpj, 11, '0');
        length = size(cpfCnpj);
      }
      const type = length === 14 ? 'CNPJ' : length === 11 ? 'CPF' : undefined;
      setIdentifier(cpfCnpj);
      setIdentifierType(type);
    }
  };

  return (
    <>
      <Header onRefreshClick={getOptions} />
      <S.Wrapper>
        <TitleWrapperRegisterUpdate />
        {!identifierType && (
          <>
            <S.Content>
              <MaskedInput
                id="cpfCnpj"
                name="cpfCnpj"
                title="CPF/CNPJ do Cliente para edição"
                value={identifier}
                onChange={(event) => {
                  setIdentifier(cpfMask(event.target.value));
                }}
                placeholder="Digite o CPF ou CNPJ"
                MaskType={'cpf'}
              />
            </S.Content>
            <S.HContainer>
              {globalContext.isLoading ? (
                ''
              ) : (
                <>
                  <Button onClick={() => history.goBack()} buttonTheme="goback">
                    Voltar
                  </Button>

                  <Button onClick={() => triggerLoadData()}>
                    Carregar cadastro
                  </Button>
                </>
              )}
            </S.HContainer>
          </>
        )}
        {identifierType === 'CPF' && (
          <PessoaFisicaForm
            customerCpf={identifier}
            municipios={municipios}
            finishCallback={onFinish}
            submitButtonLabel="ATUALIZAR CADASTRO"
          />
        )}
        {popUpDangerActive && (
          <PopUpDanger setPopUpDangerActive={setPopUpDangerActive} />
        )}
      </S.Wrapper>
      {globalContext.warningData.isActive && (
        <PopUpWarning
          title={globalContext.warningData.title}
          label={globalContext.warningData.label}
          isVehicleName={globalContext.warningData.isVehicleName}
          setWarningData={globalContext.setWarningData}
          outOfOrderVehicle={globalContext.warningData.outOfOrderVehicle}
          returnMessageVehicle={globalContext.warningData.returnMessageVehicle}
        />
      )}
      {/* <ShowPopUp handleCheckId={handleCheckId} /> */}
    </>
  );
}
