import { useState, useEffect, FormEventHandler } from 'react';
import { Dialog, DialogTitle } from '@mui/material';

import Button from 'components/Button';
import Input from 'components/Input';
import { DialogContentWrapper, DialogFooter } from './styles';

interface Props {
  open: boolean;
  handleClose: () => void;
  validationSuccess: () => void;
}

const CORRECT_ANSWER = 'holmes';

const SecurityQuestionDialog = ({
  handleClose,
  open,
  validationSuccess,
}: Props) => {
  const [answer, setAnswer] = useState<string>('');
  const [validating, setValidating] = useState<boolean>(false);
  const [wrongAnswer, setWrongAnswer] = useState<boolean>(false);

  useEffect(() => {
    clearData();
  }, [open]);

  const onChangeAnswer = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (wrongAnswer) {
      setWrongAnswer(false);
    }
    return setAnswer(target.value);
  };

  const clearData = () => {
    setAnswer('');
    setWrongAnswer(false);
  };

  const validateAnswer = () => {
    // A validação é bem simples mesmo, só pra evitar usuário leigos de fazerem algo sem saber o que estão fazendo
    setValidating(true);
    setTimeout(() => {
      if (answer === CORRECT_ANSWER) {
        validationSuccess();
        handleClose();
      } else {
        setWrongAnswer(true);
      }
      setValidating(false);
    }, 1300);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      validateAnswer();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle fontSize={18}>Controle de acesso</DialogTitle>
      <DialogContentWrapper>
        <Input
          value={answer}
          onChange={onChangeAnswer}
          onKeyDown={handleKeyDown}
          name="answer"
          title="Digite a senha para acessar este recurso"
          disabled={validating}
          error={wrongAnswer}
          errorMessage="Senha incorreta"
        ></Input>
      </DialogContentWrapper>
      <DialogFooter>
        <Button
          marginTop="0px"
          height="48px"
          width="152px"
          buttonTheme="goback"
          onClick={handleClose}
          disabled={validating}
        >
          Cancelar
        </Button>
        <Button
          marginTop="0px"
          height="48px"
          width="152px"
          onClick={validateAnswer}
          loading={validating}
        >
          Salvar
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default SecurityQuestionDialog;
