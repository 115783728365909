import styled, { css } from 'styled-components';
import { ReactSVG } from 'react-svg';

type ImageBlocked = {
  blocked: boolean;
};

export const Img = styled(ReactSVG)<ImageBlocked>`
  ${({ blocked }) => css`
    cursor: pointer;

    ${blocked &&
    css`
      path:nth-child(2) {
        fill: #999;
      }
    `}
  `}
`;

export const ImgCheck = styled.img``;
export const ImgEye = styled.img``;
export const ImgWarning = styled.img``;
export const ImgClip = styled.img``;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    width: 935px;
    padding: 25px;
    border-radius: 0 0 8px 8px;
    height: -webkit-fill-available;
  `}
`;

export const InfoBox = styled.div`
  display: grid;
  grid-template-columns: 10fr 4fr 4fr;
  gap: 9px;
  .merge {
    grid-column: span 3;
  }
`;

export const InfoDataBox = styled.div`
  ${({ theme }) => css`
    min-height: 60px;
    align-items: center;
    align-content: center;
    background-color: ${theme.colors.grayFith};
    padding: 10px;
    border-radius: 4px;
    font-weight: 200;

    &.typeI {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: left;
      label:nth-child(3) {
        text-align: center;
      }
    }
    &.typeII {
      display: grid;
      grid-template-columns: 1fr;
      text-align: center;
      justify-items: center;
    }
    .merge {
      grid-column: span 2;
    }
  `}
`;

export const TitleListBox = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 100px 100px 100px 100px 40px 100px;

    height: 20px;
    margin-top: 25px;
    text-align: center;
    justify-items: center;
    color: ${theme.colors.grayThird};
    span {
      font-size: ${theme.font.sizes.medium};
      text-align: left;
      justify-self: left;
    }

    .InternMerge {
      grid-column: span 7;
    }

    img {
      justify-self: center;
    }
  `}
`;

export const ListBox = styled(TitleListBox)`
  min-height: 270px;
  height: fit-content;

  align-content: flex-start;
  place-items: center;
  margin-top: -10px;
  span {
    min-height: 50px;
    display: flex;
    align-items: center;
  }
`;

export const ButtonBox = styled.div`
  min-height: 50px;
  padding: 0px 110px;
`;

export const ReprocessButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  div:last-child {
    margin-left: 16px;
  }
`;

export const ReprocessButtonWrapper = styled.div`
  width: 200px;
`;

export const HorizontalLine = styled.div`
  ${({ theme }) => css`
    display: block;
    border-bottom: 1px solid ${theme.colors.grayThird};
    width: 100%;

    &.longer {
      margin: 0px -2px;
      background-color: #000;
      margin-top: 10px;
    }
  `}
`;

export const ParecerText = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.green};
    font-weight: 500;

    &.Aprovado {
      color: ${theme.colors.green};
    }
    &.Rejeitado {
      color: ${theme.colors.red};
    }
    &.Aguardando {
      color: ${theme.colors.yellow};
    }
  `}
`;
