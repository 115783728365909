import Button from 'components/Button';
import React from 'react';
import * as S from './styles';
import { ReactComponent as IconWarning } from 'assets/icons/Warning.svg';
import { useHistory } from 'react-router-dom';

type ButtonsFormProps = {
  submitButtonLabel?: string;
  showActionButtonOne?: boolean;
  actionButtonOneLabel?: string;
  actionButtonOneAction?: () => void;
  showActionButtonTwo?: boolean;
  actionButtonTwoLabel?: string;
  actionButtonTwoAction?: () => void;
  isModifying?: boolean;
};

export const ButtonsForm = ({
  submitButtonLabel = 'ENVIAR PARA CRÉDITO',
  showActionButtonOne = false,
  actionButtonOneLabel,
  actionButtonOneAction,
  showActionButtonTwo = false,
  actionButtonTwoLabel,
  actionButtonTwoAction,
  isModifying,
}: ButtonsFormProps) => {
  const history = useHistory();

  const goBack = () => {
    if (isModifying) {
      history.goBack();
    } else {
      history.push('/conditions');
    }
  };

  return (
    <>
      <S.TextoAlertaDocumento>
        <IconWarning style={{ marginRight: '8px' }} width={18} height={18} />
        <b>Atualmente a Sinosserra aceita apenas clientes que possuam CNH</b>
      </S.TextoAlertaDocumento>
      <S.ButtonWrapper>
        <S.VoltarButtonWrapper onClick={goBack}>
          <Button type="button" buttonTheme="goback" height="40px">
            VOLTAR
          </Button>
        </S.VoltarButtonWrapper>
        {showActionButtonOne && (
          <S.AvancarButtonWrapper>
            <Button onClick={actionButtonOneAction} height="40px">
              {actionButtonOneLabel}
            </Button>
          </S.AvancarButtonWrapper>
        )}
        {showActionButtonTwo && (
          <S.AvancarButtonWrapper>
            <Button onClick={actionButtonTwoAction} height="40px">
              {actionButtonTwoLabel}
            </Button>
          </S.AvancarButtonWrapper>
        )}
        <S.AvancarButtonWrapper>
          <Button type="submit" height="40px">
            {submitButtonLabel}
          </Button>
        </S.AvancarButtonWrapper>
      </S.ButtonWrapper>
    </>
  );
};
