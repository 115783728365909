import * as Styled from './styles';
import * as CTX from '../../../context/globalContext';

import Skeleton from 'react-loading-skeleton';
import warningSvg from 'assets/icons/Warning.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import {
  SaveDataClientAndSendAntiFraud,
  SaveDataClientAndSendAntiFraud2,
} from 'services/client';
import { toast } from 'react-toastify';
import { useEffect, useState, useContext } from 'react';
import { telMask } from '../../../utils/mask';
import { AntiFraudData } from '..';

type PopUpAntiFraude = {
  popUpAntiFraud: AntiFraudData;
  setPopUpAntiFraud: React.Dispatch<React.SetStateAction<AntiFraudData>>;
  dealNumber: string;
  nameClient: string;
  emailClient: string;
  phoneClient: string;
  orderDetailsData: any | null;
};

export const PopUpAntiFraud = ({
  popUpAntiFraud,
  setPopUpAntiFraud,
  dealNumber,
  nameClient,
  emailClient,
  phoneClient,
  orderDetailsData,
}: PopUpAntiFraude) => {
  const globalContext = useContext(CTX.Context);
  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);

  useEffect(() => {
    if (popUpAntiFraud.isAvalista) {
      setEmail(popUpAntiFraud.emailAvalista || '');
    } else {
      setEmail(emailClient);
    }
    if (popUpAntiFraud.isAvalista) {
      setPhone(popUpAntiFraud.telefoneAvalista || '');
    } else {
      setPhone(phoneClient);
    }
  }, []);

  async function sendDataForBackend(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    globalContext.setIsLoading(true);
    const newData = {
      email: email ?? '',
      phone: phone ?? '',
    };

    let cpf: string;
    if (popUpAntiFraud.isAvalista) {
      cpf = orderDetailsData.avalistaCpfcnpj;
    } else {
      cpf = orderDetailsData.clienteCpfCnpj;
    }

    const objectSend = {
      newData,
      cpf,
      dealNumber,
    };

    let sendBackend;

    if (popUpAntiFraud.isAvalista) {
      sendBackend = await SaveDataClientAndSendAntiFraud2(objectSend);
    } else {
      sendBackend = await SaveDataClientAndSendAntiFraud(objectSend);
    }

    if (sendBackend === 'Enviado com sucesso.') {
      setPopUpAntiFraud({
        open: false,
        emailAvalista: '',
        telefoneAvalista: '',
        nomeAvalista: '',
        isAvalista: false,
      });
      globalContext.setIsLoading(false);
      toast.success('Validação enviada para o email com sucesso.');
      globalContext.RefreshPage();
    } else {
      globalContext.setIsLoading(false);
      toast.error(sendBackend);
    }
  }

  const validPhone = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const newPhone = target.value.replace(/\D/g, '');
    const newPhoneMasked = telMask(newPhone);

    return setPhone(newPhoneMasked);
  };

  return (
    <Styled.Wrapper>
      {globalContext.isLoading ? (
        <div />
      ) : (
        <Styled.BlurWrapper
          onClick={() => {
            setPopUpAntiFraud({
              open: false,
              emailAvalista: '',
              telefoneAvalista: '',
              nomeAvalista: '',
              isAvalista: false,
            });
          }}
        />
      )}

      <Styled.PopUpContainer>
        {globalContext.isLoading ? (
          <div />
        ) : (
          <CloseIcon
            onClick={() => {
              setPopUpAntiFraud({
                open: false,
                emailAvalista: '',
                telefoneAvalista: '',
                nomeAvalista: '',
                isAvalista: false,
              });
            }}
          />
        )}

        {globalContext.isLoading ? (
          <Skeleton width="150px" />
        ) : (
          <>
            <img src={warningSvg} />
            <h1>Ação necessária</h1>
            <span>
              Envie o link para o{' '}
              {popUpAntiFraud.isAvalista ? 'avalista' : 'cliente'} anexar{' '}
              <strong>SELFIE</strong> e <strong>CNH</strong>.
            </span>
            <span>
              Informação do {popUpAntiFraud.isAvalista ? 'avalista' : 'cliente'}
              :
            </span>
          </>
        )}
        <form onSubmit={sendDataForBackend}>
          {globalContext.isLoading ? (
            <Skeleton width="470px" />
          ) : (
            <>
              <div className="wrapperTextToUser marginTop marginLeftAdjusting">
                <span className="alignTextRight">Nome:</span>
                <p className="textOverflow">
                  {popUpAntiFraud.isAvalista
                    ? popUpAntiFraud.nomeAvalista
                    : nameClient}
                </p>
              </div>
              <div className="wrapperTextToUser marginTopInEditCamp">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  required
                  minLength={11}
                  maxLength={65}
                  name="email"
                  value={email ?? ''}
                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(target.value)
                  }
                />
              </div>
            </>
          )}

          {globalContext.isLoading ? (
            <Skeleton width="470px" />
          ) : (
            <>
              <div className="wrapperTextToUser marginBottom marginTopInEditCamp">
                <label htmlFor="phone">Telefone:</label>
                <input
                  type="text"
                  id="phone"
                  maxLength={15}
                  minLength={13}
                  name="phone"
                  required
                  value={phone ?? ''}
                  onChange={validPhone}
                />
              </div>
              <button
                type="submit"
                disabled={
                  !phone?.match(/-\d{4}/) || !email || email.length < 10
                }
              >
                ENVIAR PARA {popUpAntiFraud.isAvalista ? 'AVALISTA' : 'CLIENTE'}
              </button>
            </>
          )}
        </form>
      </Styled.PopUpContainer>
    </Styled.Wrapper>
  );
};
