import { useContext, useEffect, useState } from 'react';
import { useHistory, NavLink, useLocation } from 'react-router-dom';

import * as CXT from 'context/globalContext';
import logo from 'assets/images/sinosserra-branco.svg';
import logout from 'assets/icons/logout_black.svg';
import refresh from 'assets/icons/atualizar.svg';

import { toast } from 'react-toastify';

import { unMask } from 'utils/mask';
import 'firebase/analytics';
import Bugsnag from '@bugsnag/js';
import apiKeyCloak from 'api/api-keycloak';
import apiAppdsv from 'api/api-appdsv';
import apiCar from 'api/api-car';
import jwt_decode from 'jwt-decode';
import { User } from 'services/user';
import {
  CredentialType,
  TokenDataType,
} from '../../pages/Login/types/typesLogin';

import * as Styled from './styles';
import { Notification } from './componentsInHeader/Notification';
import { Company, UserData } from './types/typesUsingInHeader';
import { Stores } from './componentsInHeader/Stores';
import { flagToChangeTheStore } from 'utils/flag/flags';

export type StatusProps = {
  firstLogin?: boolean;
  onRefreshClick?: (item: any) => void;
};

export const Header = ({ firstLogin = false, onRefreshClick }: StatusProps) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const tokenData = JSON.parse(localStorage.getItem('tokenData') || '{}');
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const company = JSON.parse(localStorage.getItem('company') || '{}');
  const pathname = useLocation().pathname;

  const history = useHistory();

  const globalContext = useContext(CXT.Context);

  const [validPermissionToCP, setValidPermissionToCP] = useState<string[]>([]);
  const [openAllNotification, setOpenAllNotification] = useState(false);
  const [loggingIn, setLogginIn] = useState(false);

  const [openStoreExchange, setOpenStoreExchange] = useState(false);
  const [isAccessToBlockedStoreExchange, setIsAccessToBlockedStoreExchange] =
    useState(false);

  const [decoy, setDecoy] = useState(false);
  const [wall, setWall] = useState(true);

  useEffect(() => {
    if (
      Object.keys(userData).length > 0 &&
      userData !== undefined &&
      userData !== null
    ) {
      const userDataWithProps: UserData = userData;
      setValidPermissionToCP(userDataWithProps.permissao);
    }
    if (pathname === '/register' || pathname === '/success') {
      setIsAccessToBlockedStoreExchange(true);
    }
  }, []);

  useEffect(() => {
    if (wall) {
      setWall(false);
      return;
    }
    if (onRefreshClick) onRefreshClick(false);
  }, [globalContext.refresh]);

  function moveToCpAndSendDataForBackendCp() {
    const companyWithProps: Company = company;
    const empresaId = companyWithProps.codigo;
    const lojistaCpf = user.cpf;

    const LinkCP = process.env.REACT_APP_LINK_CP;

    window.open(`${LinkCP}${empresaId}&uc=${lojistaCpf}`, '_blank');
  }

  function closeNotification() {
    if (openAllNotification) {
      setOpenAllNotification(false);
    }
  }

  function HandleLogin(tokenData: TokenDataType) {
    const emptyToken = tokenData === undefined;
    if (emptyToken || loggingIn) return;

    setLogginIn(true);
    globalContext.setActiveTab(3);

    const acessTokenFull = `Bearer ${tokenData.access_token}`;

    apiKeyCloak.defaults.headers.Authorization = acessTokenFull;
    apiAppdsv.defaults.headers.Authorization = acessTokenFull;
    apiCar.defaults.headers.Authorization = acessTokenFull;

    localStorage.setItem('tokenData', JSON.stringify(tokenData));
    const credentialUser: CredentialType = jwt_decode(tokenData.access_token);

    localStorage.setItem('user', JSON.stringify(credentialUser));

    User.UserRequest(credentialUser.cpf)
      .then(({ data }) => {
        const user = data;

        localStorage.setItem(
          'CNPJLojista',
          JSON.stringify(user.data.cpfCnpjLojista)
        );

        localStorage.setItem('userData', JSON.stringify(user.data));

        globalContext.setAuthenticated(true);

        const salesTrackingPermission =
          user.data.permissao.findIndex(
            (permission: string) => permission === 'ACOMPANHAMENTO_VENDAS_WEB'
          ) >= 0;

        if (salesTrackingPermission)
          globalContext.setNumTab(globalContext.numTab + 1);

        const props = {
          cpf: unMask(credentialUser.cpf, '[^0-9]') ?? 'vazio',
          user: credentialUser.name ?? 'vazio',
          rede: credentialUser.company ?? 'vazio',
          departmento: credentialUser.department ?? 'vazio',
          // loja: Company.codigo,
        };

        User.CompanyRequest()
          .then((ev) => {
            globalContext.HandleCompanies(ev, user.data, props);
            globalContext.setIsLoading(false);
          })
          .catch((err) => Bugsnag.notify(err));
      })
      .catch((err) => {
        toast.error(
          '=( Não foi possível realizar o login no sistema. \nEntre em contato com o suporte!'
        );
        Bugsnag.notify(err);
        globalContext.setAuthenticated(false);
        globalContext.setIsLoading(false);
      })
      .finally(() => setLogginIn(false));
  }

  return (
    <Styled.Wrapper>
      {!globalContext.properties.cpf && HandleLogin(tokenData)}
      <Styled.NavContent>
        <img
          src={logo}
          alt="Sinosserra"
          onClick={() => {
            history.push('/client');
            closeNotification();
          }}
        />
        <div className="navBarLinks">
          {userData?.permissao?.findIndex(
            (item: string) => item === 'ACOMPANHAMENTO_VENDAS_WEB'
          ) >= 0 && (
            <Styled.NavLinkButton>
              <NavLink
                to="/register-update"
                activeClassName="actived"
                onClick={() => {
                  if (!firstLogin) {
                    globalContext.setActiveTab(1);
                    closeNotification();
                  }
                }}
              >
                Cadastro Pessoa
              </NavLink>
            </Styled.NavLinkButton>
          )}
          {userData?.permissao?.findIndex(
            (item: string) => item === 'ACOMPANHAMENTO_VENDAS_WEB'
          ) >= 0 && (
            <Styled.NavLinkButton>
              <NavLink
                to="/mydeals"
                activeClassName="actived"
                onClick={() => {
                  if (!firstLogin) {
                    globalContext.setActiveTab(2);
                    closeNotification();
                  }
                }}
              >
                Minhas Propostas
              </NavLink>
            </Styled.NavLinkButton>
          )}
          {globalContext.numTab > 0 && (
            <Styled.NavLinkButton>
              <NavLink
                to="/client"
                activeClassName="actived"
                onClick={() => {
                  if (!firstLogin) {
                    globalContext.setActiveTab(3);
                    closeNotification();
                  }
                }}
              >
                Criar Nova Proposta
              </NavLink>
            </Styled.NavLinkButton>
          )}
          {validPermissionToCP.includes('LINK_PARA_CP_WEB') && (
            <Styled.LinkAccessCP
              onClick={() => {
                moveToCpAndSendDataForBackendCp();
                closeNotification();
              }}
              title="Ir Para Credito Pessoal"
            >
              <a>Crédito Pessoal</a>
            </Styled.LinkAccessCP>
          )}
        </div>
        <Styled.UserContainer>
          <Styled.ContainerRefresh>
            <img
              src={refresh}
              alt="Refresh"
              onClick={(event) => {
                if (onRefreshClick) {
                  onRefreshClick(event);
                  closeNotification();
                } else {
                  setDecoy(!decoy);
                  closeNotification();
                }
              }}
            />
          </Styled.ContainerRefresh>
          {!isAccessToBlockedStoreExchange && flagToChangeTheStore && (
            <Stores
              onStoreSelected={() => setOpenStoreExchange(false)}
              openStoreExchange={openStoreExchange}
              setOpenStoreExchange={setOpenStoreExchange}
            />
          )}
          <Notification
            openAllNotification={openAllNotification}
            setOpenAllNotification={setOpenAllNotification}
            shopkeepersCpf={user.cpf}
          />
          <Styled.ContainerLogout>
            <span>
              Olá, <strong>{user.name.split(' ')[0]}</strong>
            </span>
            <img
              src={logout}
              alt="Logout"
              onClick={() => {
                history.push('/');
                globalContext.setPopUpTutorial(true);
              }}
            />
          </Styled.ContainerLogout>
        </Styled.UserContainer>
      </Styled.NavContent>
    </Styled.Wrapper>
  );
};
