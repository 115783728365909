import Input from 'components/Input';
import MaskedInput from 'components/MaskedInput';
import { FormikTouched } from 'formik';
import {
  EstadoCivilProps,
  ProfissaoProps,
  TipoOcupacaoProps,
} from 'pages/Register/utils/personProps';
import {
  FormPropsType,
  FormPropsWithNoAvalistaType,
} from 'pages/Register/utils/types/FormPropType/FormPropType';
import React from 'react';
import { mask, unMask } from 'utils/mask';
import * as S from '../InputsAndSelectsOfBasicInformation/styles';

type InputsForInformationAddressProps = {
  values: FormPropsType;
  errorsForm: FormPropsType | FormPropsWithNoAvalistaType | undefined;
  successForm: FormPropsType | FormPropsWithNoAvalistaType | undefined;
  touched: FormikTouched<FormPropsType>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  validOnChangeAfterError: (
    campSelect: string,
    eventTargetValue: string,
    values: FormPropsType,
    listaDeProfissao: ProfissaoProps[]
  ) => void;
  listaProfissoes: ProfissaoProps[];
  formSubmited: boolean;
  ocupationCurrentTagValidation: string;
  ocupationList: TipoOcupacaoProps[];
  maritalStatusList: EstadoCivilProps[];
  listaComprovantes: ProfissaoProps[];
};

export const InputsForInformationAddress: React.FC<
  InputsForInformationAddressProps
> = ({
  values,
  errorsForm,
  successForm,
  touched,
  setFieldValue,
  validOnChangeAfterError,
  listaProfissoes,
}) => {
  return (
    <S.Wrapper>
      <S.DivInput>
        <Input
          name="email"
          title="E-mail"
          type="email"
          value={values.email}
          maxInputLength={60}
          error={
            errorsForm?.email === 'true' && successForm?.ufEmpresa === 'false'
          }
          valid={
            !!values.email && !!touched.email && errorsForm?.email === 'false'
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('email', event.target.value);
            validOnChangeAfterError(
              'email',
              event.target.value,
              values,
              listaProfissoes
            );
          }}
        />
        <MaskedInput
          name="telefone celular"
          title={
            values.telefone.length === 11
              ? 'Telefone celular'
              : 'Telefone celular'
          }
          value={values.telefone}
          MaskType={'telefone'}
          maxInputLength={15}
          inputMethod="numeric"
          error={
            errorsForm?.telefone === 'true' && successForm?.telefone === 'false'
          }
          valid={
            !!touched.telefone &&
            !!values.telefone &&
            errorsForm?.telefone === 'false'
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('telefone', unMask(event.target.value, '[^0-9]'));
            validOnChangeAfterError(
              'telefone',
              unMask(event.target.value, '[^0-9]'),
              values,
              listaProfissoes
            );
          }}
        />
      </S.DivInput>
      <S.CEPWrapper>
        <MaskedInput
          name="cep"
          title="CEP"
          inputMethod="numeric"
          value={values.cep}
          MaskType={'cep'}
          error={errorsForm?.cep === 'true' && successForm?.cep === 'false'}
          valid={!!touched.cep && !!values.cep && errorsForm?.cep === 'false'}
          maxInputLength={9}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('cep', unMask(event.target.value, '[^0-9]'));
            validOnChangeAfterError(
              'cep',
              unMask(event.target.value, '[^0-9]'),
              values,
              listaProfissoes
            );
          }}
        />
      </S.CEPWrapper>
      <S.DivAddressOne>
        <Input
          title="Logradouro"
          name="logradouro"
          value={values.logradouro}
          maxInputLength={60}
          error={
            errorsForm?.logradouro === 'true' &&
            successForm?.logradouro === 'false'
          }
          valid={
            !!touched.logradouro &&
            !!values.logradouro &&
            errorsForm?.logradouro === 'false'
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('logradouro', event.target.value);
            validOnChangeAfterError(
              'logradouro',
              event.target.value,
              values,
              listaProfissoes
            );
          }}
        />
        <Input
          title="Número"
          name="numero"
          value={values.numero}
          maxInputLength={7}
          inputMethod="numeric"
          error={
            errorsForm?.numero === 'true' && successForm?.numero === 'false'
          }
          valid={
            !!touched.numero &&
            !!values.numero &&
            errorsForm?.numero === 'false'
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('numero', event.target.value);
            validOnChangeAfterError(
              'numero',
              event.target.value,
              values,
              listaProfissoes
            );
          }}
        />
        <Input
          title="Complemento"
          name="complemento"
          value={values.complemento}
          maxInputLength={40}
          error={
            errorsForm?.complemento === 'true' &&
            successForm?.complemento === 'false'
          }
          valid={!!touched.complemento && errorsForm?.complemento === 'false'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('complemento', event.target.value);
            validOnChangeAfterError(
              'complemento',
              event.target.value,
              values,
              listaProfissoes
            );
          }}
        />
      </S.DivAddressOne>
      <S.DivAddressTwo>
        <Input
          name="bairro"
          title="Bairro"
          value={values.bairro}
          maxInputLength={100}
          error={
            errorsForm?.bairro === 'true' && successForm?.bairro === 'false'
          }
          valid={
            !!touched.bairro &&
            !!values.bairro &&
            errorsForm?.bairro === 'false'
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('bairro', event.target.value);
            validOnChangeAfterError(
              'bairro',
              event.target.value,
              values,
              listaProfissoes
            );
          }}
        />
        <Input
          title="Cidade"
          name="cidade"
          value={values.cidade}
          maxInputLength={100}
          error={
            errorsForm?.cidade === 'true' && successForm?.cidade === 'false'
          }
          valid={
            !!touched.cidade &&
            !!values.cidade &&
            errorsForm?.cidade === 'false'
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('cidade', event.target.value.toUpperCase());
            validOnChangeAfterError(
              'cidade',
              event.target.value.toUpperCase(),
              values,
              listaProfissoes
            );
          }}
        />
        <Input
          title="UF"
          name="uf"
          value={values.uf}
          maxInputLength={2}
          error={errorsForm?.uf === 'true' && successForm?.uf === 'false'}
          valid={!!touched.uf && !!values.uf && errorsForm?.uf === 'false'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              'uf',
              event.target.value.replace(/[^A-Za-z]/g, '').toUpperCase()
            );
            validOnChangeAfterError(
              'uf',
              event.target.value.replace(/[^A-Za-z]/g, '').toUpperCase(),
              values,
              listaProfissoes
            );
          }}
        />
      </S.DivAddressTwo>
    </S.Wrapper>
  );
};
